import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { ASSET_URL, debug, fetchRecords } from "../../utils/AppUtil";
import { ModalFamilyTree } from "./ModalFamilyTree";
import { GetUserType } from "../AppType";


export const FamilyTree: React.FC = () => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [treeImages, setTreeImages] = useState<string[]>(['']);
    const [userPictureSrc, setUserPictureSrc] = useState<string>('images/fl-days-avatar.svg');
    const [userPictureId, setUserPictureId] = useState<string>('');
    const [userGender, setUserGender] = useState<string | undefined>('');

    let memorialPictureSrc = "/images/tree-avatar-placeholder.svg";
    if (memorialData.memorialPictureId) {
        memorialPictureSrc = `${ASSET_URL}/public/picture/${memorialData.memorialPictureId}`;
    }

    useEffect(() => {

        fetchRecords(commonData.token, `user/loggedIn`, processUserData)
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'user');
        });

    }, [memorialData]);

    const processUserData = (responseJson: GetUserType) => {
        setUserGender(responseJson.gender);
        if (responseJson.profilePicture?.pictureId) {
            setUserPictureSrc(`${ASSET_URL}/public/picture/${memorialData.creator.profilePicture.pictureId}`);
            setUserPictureId(memorialData.creator.profilePicture.pictureId);
        }
    }

    const handleTreeImages = (images: string[]) => {
        setTreeImages(images);
    }

    return (
        <>
            <div className="d-flex flex-column p-3">

                <p className="memorial-family-tree-section-label">{memorialData.firstName}’s Family</p>
            
                <div className="home-family-tree-card my-3">

                    <div className="tree-tile-body">
                        <p className="tree-name">{memorialData.creator.lastName} Family</p>
                        
                        <div className="tree-images-container">
                            <div className="line faded" />
                            
                            <div className={`tree-images ${treeImages.length > 3 && 'overflow'}`}>
                                <div className="tree-img-wrapper">
                                    <div className="line" />
                                    {treeImages[0] ?
                                        (treeImages[0] === 'proxy' ?
                                            <img src="images/fl-avatar-borderless-1.svg" width={48} height={48} /> :
                                            <img src={`${ASSET_URL}/public/picture/${treeImages[0]}`} width={48} height={48} />
                                        ) : <div className="tree-no-image" />
                                    }
                                </div>

                                <div className="tree-img-wrapper">
                                    <div className="line" />
                                    {treeImages[1] ?
                                        (treeImages[1] === 'proxy' ?
                                            <img src="images/fl-avatar-borderless-1.svg" width={48} height={48} /> :
                                            <img src={`${ASSET_URL}/public/picture/${treeImages[1]}`} width={48} height={48} />
                                        ) : <div className="tree-no-image" />
                                    }
                                </div>

                                <div className="tree-img-wrapper">
                                    <div className="line" />
                                    {treeImages[2] ?
                                        (treeImages[2] === 'proxy' ?
                                            <img src="images/fl-avatar-borderless-1.svg" width={48} height={48} /> :
                                            <img src={`${ASSET_URL}/public/picture/${treeImages[2]}`} width={48} height={48} />
                                        ) : <div className="tree-no-image" />
                                    }
                                </div>

                                {treeImages.length > 3 &&
                                    <div className="tree-img-wrapper">
                                        <div className="line" />
                                        <div className="overflow-image" >
                                            <p>+ {treeImages.length - 3}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <ModalFamilyTree treeImages={handleTreeImages} userGender={userGender} userPicture={userPictureId} creator={memorialData.creator} endpoint={memorialData.creator.userId} />
                </div>            
            </div>
        </>
    );
}