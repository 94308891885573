import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../AppContext";
import { ModalCreateAccount } from "../components/Modals/ModalCreateAccount";
import { LoginSuccess } from "../components/Modals/ModalLoginSuccess";
import { MemorialContext } from "../MemorialContext";
import { GetUserType, LoginStateType} from "../components/AppType";
import { useLocation } from "react-router-dom";
import { ASSET_URL, fetchRecords } from "../utils/AppUtil";


export const Login = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const baseUrlLogin: any = process.env.REACT_APP_BASE_LOGIN;
    const loginEndpoint = `${baseUrlLogin}`;
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const [inputFocus, setInputFocus] = useState<boolean>(false);
    const [showInviteScreen, setShowInviteScreen] = useState<boolean>(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const inviteId = queryParams.get('id');
    const inviteUserFirstName = queryParams.get('userFirstName');
    const inviteUserLastName = queryParams.get('userLastName');
    const inviteUserImage = queryParams.get('userImage');
    const inviteMemorialFirstName = queryParams.get('memorialFirstName');
    const inviteMemorialLastName = queryParams.get('memorialLastName');
    const inviteType = queryParams.get('type');
   
    const [loginState, setLoginState] = useState<LoginStateType>({
        loginFailedDisplay: false,
        loginFailedColor: '',
        loggedOut: true,
        loggedOutState: true,
        formState: {
            email: '',
            password: '',
            inviteId: ''
        }
    });

    useEffect(() => {
        if ((inviteId && inviteId !== '')) {
            setLoginState((loginState) => ({
                ...loginState,
                formState: {
                    ...loginState.formState,
                    inviteId: inviteId
                }
            }));

            setShowInviteScreen(true);
        }
    }, [inviteId]);
    
    const handleFormChange = (e: React.ChangeEvent<any>) => {
        const { name, value, type } = e.target;
        if (type === "email" || type === "password") {
            setLoginState(prevState => ({
                ...prevState,
                formState: {
                    ...prevState.formState,
                    [name]: value
                }
            }));
        } 
    }
  
    const passwordGroup = document.getElementsByClassName('form-password');
    const password = passwordGroup[0] as HTMLInputElement;

    const handlePasswordShow = () => {
        if (password) {
            if (password.type === "password") {
                password.type = "text";
            } else {
                password.type = "password";
            }
        }
    }

    const postLoginData = async (endpoint: string, data: any, success: any, failure: any) => {
        console.log("LOGIN DATA", JSON.stringify(data));
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setCommonData(await response.json());
                document.getElementById('loginSuccessModal')?.click();
            } else {
                failure("Login Failed", response);
                setLoginState(prevState => ({
                    ...prevState,
                    loginFailedDisplay: true, 
                    loginFailedColor: '#DC3545'
                }));
            }
        } catch (error) {
            failure(error);
        }
    }

    const handleLoginClick = (event: React.MouseEvent): void => {
        const { formState } = loginState; 
        postLoginData(loginEndpoint, formState,
            (response:any) => {
                console.log("TOKEN", response);
            },
            (response:any) => {
                console.log("FAILED",response);
        })
    }

    const loadUser = (val: string, event: React.MouseEvent) => {
        event.preventDefault();

        if (emailInputRef.current && passwordInputRef.current) {
            emailInputRef.current.value = val;
            passwordInputRef.current.value = "PWD"
            passwordInputRef.current.type = "text";

            setLoginState((prevState) => ({
                ...prevState,  
                formState: {  
                    ...prevState.formState, 
                    email: val,           
                    password: 'PWD' 
                }
            }));
        }
    }
 
    const handleLogoutClick = () => {
        setLoginState(prevState => ({
            ...prevState,  
            loggedOut: true,  
            loggedOutState: true
        }));
    }

    const handleHaveAccountClick = () => {
        setLoginState(prevState => ({
            ...prevState, 
            loggedOut: false,
            loggedOutState: false 
        }));
    }

    const handleAcceptInvite = () => {
        setShowInviteScreen(false);
    }
  
    return (
        <>
            <LoginSuccess />

            {showInviteScreen ?
                <div className="accept-invite-screen">
                    <div className="accept-invite-wrapper">
                        <img src="images/fl-close-gray.svg" className="close" onClick={handleAcceptInvite} />

                        {inviteUserImage && inviteUserImage !== '' ?
                            <img src={`${ASSET_URL}/public/picture/${inviteUserImage}`} className="invite-user-image" />
                            :
                            <img src="images/fl-days-story-default-avatar.svg" className="invite-user-image" />
                        }

                        {inviteType === 'tree' ?
                            <p>
                                <span className="invite-from">{inviteUserFirstName} {inviteUserLastName}</span>
                                &nbsp;invited you to join the&nbsp;
                                <span className="invite-to">{inviteMemorialLastName}</span>
                                &nbsp;family tree
                            </p>
                            :
                            <p>
                                <span className="invite-from">{inviteUserFirstName} {inviteUserLastName}</span>
                                &nbsp;invited you to {inviteType === 'collaborator' ? 'collaborate on' : 'view'}&nbsp;
                                <span className="invite-to">{inviteMemorialFirstName} {inviteMemorialLastName}'s</span>
                                &nbsp;memorial
                            </p>
                        }

                        <div className="btn fl-btn-white w-100 mt-2" onClick={handleAcceptInvite}>Accept</div>
                    </div>
                </div>
                :
                <>
                    {!loginState.loggedOut &&
                        <div className="page-wrapper page-login">
                            <div className="login-top d-flex justify-content-center">
                                <div className="dropdown login-user-select">
                                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">User</button>
                                    <ul className="dropdown-menu">
                                        <li><button onClick={(e) => {loadUser('aaa@acme.com', e)}} className="dropdown-item">Tina Halevi</button></li>
                                        <li><button onClick={(e) => {loadUser('bbb@acme.com', e)}} className="dropdown-item">Lionel Turner</button></li>
                                        <li><button onClick={(e) => {loadUser('ccc@acme.com', e)}} className="dropdown-item">Joan Wilkinson</button></li>
                                        <li><button onClick={(e) => {loadUser('ddd@acme.com', e)}} className="dropdown-item">Patrick Jordan</button></li>
                                        <li><button onClick={(e) => {loadUser('eee@acme.com', e)}} className="dropdown-item">Jan Grinch</button></li>
                                    </ul>
                                </div>

                                <p className="login-title">{ t("Login.heading", {ns: "components"}) }</p>

                                <div className="btn btn-modal-close" onClick={handleLogoutClick}>
                                    <img src="/images/modal-close-x.svg"/>
                                </div>

                            </div>
                            
                            <form className="login-form p-3">
                                <div className="d-flex flex-column justify-content-between h-100">
                                        
                                    {/* Form inputs here  */}
                                    <div className="d-flex flex-column gap-4">
                                        <div>
                                            <label htmlFor="email" className="form-label">{ t("Login.Form.Email.label", {ns: "components"}) }</label>
                                            <input ref={emailInputRef} id="email" name="email" onChange={handleFormChange} type="email" className="form-control" placeholder={ t("Login.Form.Email.placeholder", {ns: "components"}) } style={{borderColor: loginState.loginFailedColor}} required />
                                        </div>

                                        <div className="mb-4" style={{position: "relative"}}>
                                            <label htmlFor="password" className="form-label">{ t("Login.Form.Password.label", {ns: "components"}) }</label>
                                            <input ref={passwordInputRef} id="password" name="password" onChange={handleFormChange} type="password" className="form-control form-password" placeholder={ t("Login.Form.Password.placeholder", {ns: "components"}) } style={{borderColor: loginState.loginFailedColor}} required />
                                            <img src="/images/fl-eye.svg" onClick={handlePasswordShow} style={{position: "absolute", top: "63%", right: "16px", cursor: "pointer"}} alt="" />
                                        </div>

                                        { loginState.loginFailedDisplay &&
                                            <div className="failed-login-block">
                                                <p>Incorrect email or password</p>
                                            </div>
                                        }

                                        <div className="login-footer d-flex justify-content-center">
                                            <a href="/">{ t("Login.Account.forgotPassword", {ns: "components"}) }</a>
                                        </div>
                                    </div>

                                    <div className="login-footer d-flex flex-column gap-3 mb-2">

                                        {/* Form submit here  */}
                                        <button type="button" onClick={handleLoginClick} className="btn fl-btn-login">{ t("Login.heading", {ns: "components"}) }</button>
                                        <p>{ t("Login.Account.copy", {ns: "components"}) } <span onClick={handleLogoutClick} style={{textDecoration: 'underline'}}>create an account</span></p>
                                    </div>
                                </div>
                            </form>

                        </div>
                    }

                    {loginState.loggedOut &&
                        <>
                            <div className="logged-out-wrapper d-flex flex-column justify-content-between p-4 pb-5">
                                <div className={`logged-out-background ${inputFocus && 'active'}`} />

                                <div className="d-flex w-100">
                                    <img src="/images/fl-logo-text.svg" height={20} alt=""/>
                                </div>

                                <div className="logged-out-bottom">
                                    <div className="title-text">
                                        <p>Memories fade.</p>
                                        <p>Keep their stories alive.</p>
                                    </div>

                                    <ModalCreateAccount loggedOutState={loginState.loggedOutState} setInputFocus={setInputFocus} inviteId={inviteId ? inviteId : ''} />

                                    <p className={`account-exists-text ${inputFocus && 'active'}`}>I already have an account. <span onClick={handleHaveAccountClick} style={{textDecoration: "underline", cursor: "pointer"}}>Login</span></p>
                                </div>
                            </div>
                        </>
                    }
                </>
                
            }

                
        </>
    );
}


