import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { CreateLifeEvent } from "./CreateLifeEvent";
import { CreateMemory } from "./CreateMemory";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import 'animate.css';

type ModalProps = {
    onFileUpload: (uploadStatus: boolean, croppingStatus: boolean, count: number, total: number, error: Error | null) => void;
    show: boolean;
    onHide: () => void;
    onEventCreated: () => void;
    eventType: string,
    isFromHome: boolean
}

export const ModalCreateEvent: React.FC<ModalProps> = ({onFileUpload, show, onHide, onEventCreated, eventType, ...props}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [modalClass, setModalClass] = useState<string>('')
    const [selectedEventType, setSelectedEventType] = useState<string>('')
    const [lockScroll, setLockScroll] = useState<boolean>(false);

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (lockScroll) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };

        
    }, [lockScroll, show]);

    useEffect(() => {
        setModalClass('');
        setSelectedEventType(eventType);
    }, [show]);

    const handleModalHide = () => {
        setModalClass('animate__animated animate__fadeOut animate__faster');
        
        setTimeout(() => {
            setModalClass('modal-hide');
        }, 1000);
    }

    const handleFileUpload = (uploadStatus: boolean, croppingStatus: boolean, count: number, total: number, error: Error | null) => {
        onFileUpload(uploadStatus, croppingStatus, count, total, error);

        setLockScroll(false);
        
        if (!uploadStatus) {
            onHide();
            setTimeout(() => {
                onEventCreated();
            }, 500);
        }
    }

    const handleClose = () => {
        setModalClass('');
        onHide();
    }

    const handleUploadReset = () => {
        onFileUpload(false, false, 0, 0, null);
    }

    const changeEventType = () => {
        setSelectedEventType('Memory');
    }

    return (
        <>        
            <Modal className={`modal-create-event ${modalClass}`} show={show} fullscreen={true} onHide={handleClose} onShow={handleUploadReset} backdrop={false}>
                {selectedEventType === 'lifeEvent' && <CreateLifeEvent onFileUpload={handleFileUpload} onModalHide={handleModalHide} onModalClose={handleClose} changeEvent={changeEventType} />}
                {selectedEventType === 'memory' && <CreateMemory onFileUpload={handleFileUpload} onModalHide={handleModalHide} onModalClose={handleClose} />}
            </Modal>
        </>
    );
}





