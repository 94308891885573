import React, { useCallback, useEffect, useState } from "react";
import { Handle, Position, useEdges } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import "animate.css"

type NodeProps = {
    data: {
        id: string,
        position: { x: number, y: number },
        fromTreeNodeId: string,

        displayType: string,
        imageSrc: string,
        firstName: string,
        lastName: string,
        relation: string,

        targetHandle: Position,
        sourceHandleTop: Position,
        sourceHandleRight: Position,
        sourceHandleBottom: Position,
        sourceHandleLeft: Position,

        targetHandleId: string,
        sourceHandleTopId: string,
        sourceHandleRightId: string,
        sourceHandleBottomId: string,
        sourceHandleLeftId: string,

        handleExtentionClick: (
            firstName: string,
            posX: number,
            posY: number,
            relationFrom: string,
            treeNodeId: string
        ) => void,
        handleNodeClick: (
            id: string,
            fromTreeNodeId: string,
            relation: string,
            posX: number,
            posY: number
        ) => void
    }
}

export const DefaultNode: React.FC<NodeProps> = ({ data }) => {

    // const [showDetailed, setShowDetailed] = useState<boolean>(false);
    const [showExtension, setShowExtension] = useState<boolean>(true);
    const edges = useEdges(); // Get all edges in the graph

    const checkHandleConnected = (handleId: string) => {
        return edges.some(edge => 
            edge.sourceHandle === handleId || edge.targetHandle === handleId
        );
    };

    const handleIds = [
        data.sourceHandleTopId,
        data.sourceHandleRightId,
        data.sourceHandleBottomId,
        data.sourceHandleLeftId
    ];

    useEffect(() => {

        // Check if any handle is connected
        const anyConnected = handleIds.some((handleId: string) => checkHandleConnected(handleId));
        
        if (anyConnected) {
            setShowExtension(false);
        }
    }, [handleIds, checkHandleConnected]);

    const handleStyle = (handleId: string) => {
        return {
            background: 'white',
            display: checkHandleConnected(handleId) ? 'block' : 'none'
        };
    };

    const onChange = useCallback((evt: any) => {
        console.log(evt.target.value);
    }, []);

    return (
        <>
            <Handle
                id={data.targetHandleId}
                type="target"
                position={data.targetHandle}
                style={{background: 'white'}} />

            <Handle
                id={data.sourceHandleTopId}
                type="source"
                position={data.sourceHandleTop}
                style={handleStyle(data.sourceHandleTopId)} />

            <Handle
                id={data.sourceHandleRightId}
                type="source"
                position={data.sourceHandleRight}
                style={handleStyle(data.sourceHandleRightId)} />

            <Handle
                id={data.sourceHandleBottomId}
                type="source"
                position={data.sourceHandleBottom}
                style={handleStyle(data.sourceHandleBottomId)} />

            <Handle
                id={data.sourceHandleLeftId}
                type="source"
                position={data.sourceHandleLeft}
                style={handleStyle(data.sourceHandleLeftId)} />

            <div className={`family-tree-node ${data.displayType}-node`}
                onClick={() => data.handleNodeClick(
                    data.id,
                    data.fromTreeNodeId,
                    data.lastName,
                    data.position.x,
                    data.position.y
                )}
            >
                <p className="node-name">{data.firstName}</p>
                <p className="node-name">{data.lastName}</p>

            </div>
        </>
      );

}