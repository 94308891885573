import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { debug, deleteRecord, fetchRecords, postFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { familyTreeGetType, familyTreeNode, familyTreePostType, familyTreeUpdateType, GetUserType, MemorialType, UserType } from "../AppType";
import { AddRelativePopup } from "./AddRelativePopup";
import { DeceasedPlaceholderPopup } from "./DeceasedPlaceholderPopup";
import { LivingPlaceholderPopup } from "./LivingPlaceholderPopup";
import { ReactFlow, Background, Controls, applyEdgeChanges, applyNodeChanges, Position, BackgroundVariant, Edge, NodeChange, addEdge } from '@xyflow/react';
import { TreeNode } from "./TreeNode";
import { DetailedNode } from "./DetailedNode";
import { DefaultNode } from "./DefaultNode";
import { LinkMemorial } from "./LinkMemorial";
import '@xyflow/react/dist/style.css';
import "animate.css"
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";
import { EditRelationship } from "./EditRelationship";

type AddRelativeType = {
    name: string,
    posX?: number,
    posY?: number,
}

type ModalProps = {
    handleViewMemorial?: () => void;
    userGender?: string;
    userPicture?: string;
    treeImages?: (images: string[]) => void;
    creator?: GetUserType;
    endpoint: string;
}

export const ModalFamilyTree: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal handling states
    const [show, setShow] = useState(false);
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalSuccessAnimation, setModalSuccessAnimation] = useState<string>('animate__animated animate__slideInRight');

    const [isLoggedInUserTree, setIsLoggedInUserTree] = useState<boolean>(false);

    // Popup display states to trigger after adding a proxy node
    const [deceasedRelativePopupOpen, setDeceasedRelativePopupOpen] = useState<boolean>(false);
    const [livingRelativePopupOpen, setLivingRelativePopupOpen] = useState<boolean>(false);

    const [addRelativePopup, setAddRelativePopup] = useState<boolean>(false);
    const [addRelativeData, setAddRelativeData] = useState<AddRelativeType>()

    // Detailed node view states
    const [detailedNodeActive, setDetailedNodeActive] = useState<boolean>(false);
    const [detailedNodeType, setDetailedNodeType] = useState<string>("");
    const [detailedNodeMemorial, setDetailedNodeMemorial] = useState<MemorialType>();
    const [detailedNodeUser, setDetailedNodeUser] = useState<GetUserType>();
    const [ownerNodeUser, setOwnerNodeUser] = useState<GetUserType>();
    const [endpoint, setEndpoint] = useState<string>("")

    const [createdNodes, setCreatedNodes] = useState<number>(0);

    const [edgeType, setEdgeType] = useState<string>("step");

    const detailedNodeDefault: familyTreeUpdateType = {
        treeNodeId: "",
        userId: "",
        memorialId: "",
        relationship: "",
        data: {
            posX: 0,
            posY: 0,
            fromTreeNodeId: [],
            handleFrom: "",
            handleTo: ""
        }
    }
    const [detailedNodeData, setDetailedNodeData] = useState<familyTreeUpdateType>(detailedNodeDefault);   
    const [nodePictureId, setNodePictureId] = useState<string>("")
    
    const [selectedRelative, setSelectedRelative] = useState<string>('');
    const [hasPassed, setHasPassed] = useState<boolean>(false);

    const [formStep, setFormStep] = useState<number>(0);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const [draggedNodePositions, setDraggedNodePositions] = useState<Record<string, { x: number, y: number }>>({});

    const [linkMemorialModalOpen, setLinkMemorialModalOpen] = useState<boolean>(false);
    const [createMemorialModalOpen, setCreateMemorialModalOpen] = useState<boolean>(false);
    const [editRelationshipModalOpen, setEditRelationshipModalOpen] = useState<boolean>(false);

    const [availableConnections, setAvailableConnections] = useState<familyTreeNode[]>();

    // Ref to store the initial Y position of the node when it's grabbed
    const initialYRef = useRef<number>(0);

    // formData for posting a new proxy node to the DB
    const formData: familyTreePostType = {
        treeNodeId: "",
        userId: "",
        memorialId: "",
        proxyFirstName: "",
        proxyLastName: "",
        proxyType: "USER",
        relationship: "",
        data: {
            posX: 0,
            posY: 0,
            fromTreeNodeId: [],
            handleFrom: "",
            handleTo: ""
        }
    }
    const [formState, setFormState] = useState<familyTreePostType>(formData);

    // ==================
    // CREATING NEW NODES
    // ==================
    // #region

        const handleAddRelativePopup = (firstName: string, posX: number, posY: number, fromTreeNodeId: string) => {
            setAddRelativePopup(true);

            setAddRelativeData((addRelativeData) => ({
                ...addRelativeData,
                name: firstName,
                posX: posX,
                posY: posY,
            }));

            setFormState((formState) => ({
                ...formState,
                data: {
                    ...formState.data,
                    fromTreeNodeId: formState.data.fromTreeNodeId.concat(fromTreeNodeId),
                },
            }));
        }

        const handleDetailedNode = (
            treeNodeId: string,
            user: GetUserType,
            memorial: MemorialType,
            firstName: string,
            lastName: string,
            proxyType: string,
            relationship: string,
            posX: number,
            posY: number,
            handleTo: string,
            handleFrom: string,
            fromTreeNodeId: [],
            displayType: string,
            pictureId: string,
            endpoint: string
        ) => {

            console.log("FROM TREE NODE ID: ", fromTreeNodeId);
            setDetailedNodeActive(true);
            setDetailedNodeType(displayType);
            setDetailedNodeMemorial(memorial);
            setDetailedNodeUser(user);
            setNodePictureId(pictureId);

            setDetailedNodeData(detailedNodeData => ({
                ...detailedNodeData,
                treeNodeId: treeNodeId,
                userId: user?.userId,
                memorialId: memorial?.memorialId,
                proxyFirstName: firstName,
                proxyLastName: lastName,
                proxyType: proxyType,
                relationship: relationship,
                endpoint: endpoint,
                data: {
                    posX: posX,
                    posY: posY,
                    handleTo: handleTo,
                    handleFrom: handleFrom,
                    fromTreeNodeId: fromTreeNodeId
                }
            }));
        }

        const handleAddRelative = (relation: string, addPosX: number, addPosY: number, handleFrom: string, handleTo: string) => {
            setSelectedRelative(relation);

            setFormState((formState) => ({
                ...formState, 
                relationship: relation,
                data: {
                    ...formState.data,
                    handleFrom: handleFrom,
                    handleTo: handleTo
                }
            }));

            // CLEAN UP HERE start

            const newPosY: number = addRelativeData?.posY as number + addPosY;
            const newPosX: number = addRelativeData?.posX as number + addPosX;
            
            setFormState((formState) => ({
                ...formState, 
                data: {
                    ...formState.data,
                    posX: newPosX,
                    posY: newPosY
                }
            }));

            // CLEAN UP HERE end

            setTimeout(() => {
                setFormStep(1);
            }, 1000);
        }

        const handleAddFatherMother = (id: string, fromTreeNodeId: string[], relation: string, posX: number, posY: number) => {
            setSelectedRelative(relation);

            setAddRelativeData((addRelativeData) => ({
                ...addRelativeData,
                name: commonData.firstName
            }));

            setFormState((formState) => ({
                ...formState, 
                treeNodeId: id,
                relationship: relation,
                data: {
                    ...formState.data,
                    handleFrom: 'top',
                    handleTo: 'bottom' ,
                    posX: posX,
                    posY: posY,
                    fromTreeNodeId: fromTreeNodeId
                }
            }));

            setFormStep(1);
        }

        const getNodeDataByIds = (nodeIds: string[], nodes: familyTreeNode[]): familyTreeNode[] => {
           return nodes.filter((node) => nodeIds.includes(node.id));
        }
    
    // #endregion



    // =====================
    // INITIAL NODES & EDGES
    // =====================
    // #region

        const nodeTypes = {node: TreeNode, defaultNode: DefaultNode};

        let originNodeData: GetUserType | undefined = {
            userId: commonData.userId,
            firstName: commonData.firstName,
            lastName: commonData.lastName,
            email: commonData.email,
            profilePicture: {
                pictureId: "",
                isVideo: true
            }
        };

        if (!isLoggedInUserTree) {
            originNodeData = props.creator;
        }

        const [nodes, setNodes] = useState<familyTreeNode[]>([]);
        const [edges, setEdges] = useState<Edge[]>([]);


    // #endregion



    // ===============================
    // USEEFFECTS, ZOOMING & SCROLLING
    // ===============================
    // #region

        useEffect(() => {
            setEndpoint(props.endpoint); // Update the state whenever props change

            setLivingRelativePopupOpen(false);
            setDeceasedRelativePopupOpen(false);
        }, [props.endpoint, show]);
        
        // Fetch family tree data whenever endpoint or dependencies change
        useEffect(() => {
            if (!endpoint) return; // Ensure endpoint is set before fetching
        
            fetchRecords(commonData.token, `user/${endpoint}/familyTree`, processFamilyTreeData)
            .catch((error) => {
                debug('fetchRecords error: ' + error, 'FamilyTree');
            });
        
            setIsLoggedInUserTree(endpoint === 'loggedIn');
        }, [endpoint, refreshCounter, detailedNodeData]);

        useEffect(() => {
            // Combine initial nodes with dynamic nodes
            setNodes(nodes);
        }, [nodes]); // Recalculate whenever dynamic nodes change

        useEffect(() => {
            // let currnetConnections: string[] = [];

            // const detailedNode = Array.isArray(detailedNodeData.data.fromTreeNodeId)
            //     ? detailedNodeData.data.fromTreeNodeId
            //     : [detailedNodeData.data.fromTreeNodeId]; // Ensure it's an array

            //     detailedNode.forEach((connection) => {
            //     currnetConnections.push(connection);
            // });

            // // setConnectedNodes(getNodeDataByIds(currnetConnections, nodes));

            let posY: number = detailedNodeData.data.posY;

            if (posY > 0) posY = posY - 250;
            else posY = posY + 250;

            const filteredNodes = nodes.filter((node) => node.position.y === posY);

            setAvailableConnections(filteredNodes);
        }, [detailedNodeData]);

        // Disable vertical scrolling on body when modal is open
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => e.preventDefault();
        
            if (show) {
                document.body.style.position = 'fixed';
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            }
        
            return () => {
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [show]);

        // Disable DOM zooming to allow pinch zooming
        const deactivateZoomDiv = document.getElementById('deactivateZoom');
        deactivateZoomDiv?.addEventListener('touchmove', function(event) {
            const touchEvent = event as TouchEvent & { scale: number };
            if (touchEvent.scale !== 1) {
                event.preventDefault();
            }
        }, {passive: false});

    // #endregion

    

    // =====================================
    // PROCESS DATA FUNCTIONS (NODES & EDGES)
    // =====================================
    // #region


        const processFamilyTreeData = (responseJson: familyTreeGetType[]) => {
            setNodes(buildFamilyTreeNodes(responseJson));
            setEdges(buildFamilyTreeEdges(responseJson));
        };
        

        // Build tree nodes
        const buildFamilyTreeNodes = (response: familyTreeGetType[]): any[] => {
            let key: number = 0;

            const result: any[] = [];
            const imageArray: string[] = [];
            const proxyArray: string[] = [];

            response.forEach((node: familyTreeGetType) => {

                let nodeType: string = 'node';
                let displayType: string = '';
                let firstName: string = '';
                let lastName: string = '';
                let picture: string | undefined = '';

                if (node.proxyType === 'DEFAULT') {
                    nodeType = 'defaultNode';
                }
                
                if (node.user) {
                    if (node.relationship === 'You') {
                        displayType = 'owner';
                        setOwnerNodeUser(node.user);
                    } else displayType = 'user';

                    firstName = node.user.firstName;
                    lastName = node.user.lastName;
                    picture = node.user.profilePicture?.pictureId;

                    if (picture !== '' && picture !== undefined) {
                        if (node.relationship === 'You') imageArray.unshift(picture);
                        else imageArray.push(picture);
                    } else proxyArray.push('proxy');

                } else if (node.memorial) {
                    displayType = 'memorial';
                    firstName = node.memorial.firstName;
                    lastName = node.memorial.lastName;
                    picture = node.memorial.memorialPictureId;
                    if (picture !== '' && picture !== null) imageArray.push(picture);
                    else proxyArray.push('proxy');

                } else {
                    firstName = node.proxyFirstName;
                    lastName = node.proxyLastName;
                    if (node.proxyType !== 'DEFAULT') proxyArray.push('proxy');

                    if (node.proxyType === 'USER') displayType = 'proxy-user';
                    else if (node.proxyType === 'MEMORIAL') displayType = 'proxy-memorial';
                    else displayType = 'proxy-default';
                }

                if (node.treeNodeId !== 'originNode' && node.treeNodeId !== 'addFatherNode' && node.treeNodeId !== 'addMotherNode') setCreatedNodes(c => c+1);

                result.push(
                    {
                        key: key,
                        id: node.treeNodeId,
                        position: { x: node.data?.posX, y: node.data?.posY },
                        type: nodeType,
                        draggable: true,
                        data: {
                            id: node.treeNodeId,
                            position: { x: node.data?.posX, y: node.data?.posY },   
                            fromTreeNodeId: node.data.fromTreeNodeId,
                            handleFrom: node.data.handleFrom,

                            user: node.user ? node.user : '',
                            memorial: node.memorial ? node.memorial : '',
                            proxyType: node.proxyType ? node.proxyType : '',

                            displayType: displayType,
                            firstName: firstName,
                            lastName: lastName,
                            relation: node.relationship,
                            pictureId: picture,

                            targetHandle: node.data?.handleTo,
                            sourceHandleTop: Position.Top,
                            sourceHandleRight: Position.Right,
                            sourceHandleBottom: Position.Bottom,
                            sourceHandleLeft: Position.Left,

                            targetHandleId: `target-${node.treeNodeId}`,
                            sourceHandleTopId: `source-top-${node.treeNodeId}`,
                            sourceHandleRightId: `source-right-${node.treeNodeId}`,
                            sourceHandleBottomId: `source-bottom-${node.treeNodeId}`,
                            sourceHandleLeftId: `source-left-${node.treeNodeId}`,

                            endpoint: endpoint,

                            handleExtentionClick: handleAddRelativePopup,
                            handleClickToTree: handleClickToTree,
                            handleNodeClick: node.proxyType === 'DEFAULT' ? handleAddFatherMother : handleDetailedNode,
                        }
                    }
                );

                key ++;
            });

            const finalImageIdArray = [...imageArray, ...proxyArray];

            if (props.treeImages) props.treeImages(finalImageIdArray);
            return result;
        }

        // Build node edges
        const buildFamilyTreeEdges = (response: familyTreeGetType[]): any[] => {
            let key: number =   0;
            const result: any[] = [];

            response.forEach((node: familyTreeGetType) => {

                const edges = Array.isArray(node.data.fromTreeNodeId)
                            ? node.data.fromTreeNodeId
                            : [node.data.fromTreeNodeId]; // Ensure it's an array

                            // console.log("EDGES: ", edges);

            
                edges.forEach((edge: string) => {
                    result.push({
                        key: key,
                        id: `${key}->${edge}->${node.treeNodeId}`,
                        source: edge,
                        target: node.treeNodeId,
                        sourceHandle: `source-${node.data?.handleFrom}-${edge}`,
                        targetHandle: `target-${node.treeNodeId}`,
                        type: edgeType,
                        style: { stroke: '#1A323C', strokeWidth: 2 }
                    });
    
                    key ++;
                });

            });

            return result;
        }

        // const updateNodes = (nodes: familyTreeNode[], edges: Edge[]): familyTreeNode[] => {
        //     // Find direct connections from 'originNode'
        //     const connectedNodes = edges
        //         .filter(edge => edge.source === 'originNode') // Edges originating from 'originNode'
        //         .map(edge => edge.target); // Target node IDs
        
        //     // Check if any connected node has 'relation' of 'Mother' or 'Father'
        //     const hasMother = nodes.some(node => connectedNodes.includes(node.id) && node.data.relation === 'Mother');
        //     const hasFather = nodes.some(node => connectedNodes.includes(node.id) && node.data.relation === 'Father');
        
        //     // Filter out 'addMotherNode' or 'addFatherNode' if conditions are met
        //     return nodes.filter(node => {
        //         if (node.id === 'addMotherNode' && hasMother) {
        //             return false; // Remove 'addMotherNode'
        //         }
        //         if (node.id === 'addFatherNode' && hasFather) {
        //             return false; // Remove 'addFatherNode'
        //         }
        //         return true; // Keep all other nodes
        //     });
        // }

    // #endregion



    // ============================
    // REACT FLOW HANDLER FUNCTIONS
    // ============================
    // #region

        const onNodesChange = useCallback(
            (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
            [setNodes],
        );

        const onEdgesChange = useCallback(
            (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
            [setNodes],
        );

        const onConnect = useCallback(
            (params: any) => setEdges((eds) => addEdge(params, eds)),
            [setNodes],
        );

        // Handle the start of dragging
        const handleNodeDragStart = useCallback((event: React.MouseEvent, node: familyTreeNode) => {

            // Capture the Y position of the node when dragging starts and store it in the ref
            initialYRef.current = node.position.y;
            console.log("Node grabbed. Y position captured:", node.position.y);
        }, []);

        // Set nodes when change detected
        const handleNodesChange = useCallback((changes: NodeChange[]) => {
            setNodes((nds) =>
            applyNodeChanges(
                changes.map((change) => {
                    if (change.type === 'position') {
                        const node = nds.find((n) => n.id === change.id);
                        if (node) {
                        return {
                            ...change,
                            type: "position", // Set to the literal "position" as required
                            position: { x: change.position?.x ?? node.position.x, y: initialYRef.current },
                        };
                        }
                    }

                    return change;
                }),
                nds
            ) as familyTreeNode[]
            );
            
        }, []);

        // Node dragging OLD
        const handleNodeDragStopOLD = useCallback((event: React.MouseEvent, node: familyTreeNode) => {
            
            // Capture the new X position and keep the Y position unchanged
            const newPosition = {
                x: node.position.x, // update X position
                y: initialYRef.current, // keep Y position the same
            };

            // Log the updated position (only X should change)
            console.log('New X position:', newPosition.x);
            console.log('Y position is locked:', newPosition.y);

            // Update state with the new position (keeping Y fixed)
            setNodes((prevNodes) =>
                prevNodes.map((n) =>
                    n.id === node.id ? { ...n, position: newPosition } : n
                )
            );

            const formDataReposition = {
                posX: Math.ceil(node.position.x),
                posY: initialYRef.current,
            }

            postFormData(
                commonData.token,
                `user/loggedIn/familyTree/${node.id}/data`,
                formDataReposition,
                (response: any) => {
                    console.log("data sent! ----->", response);
                },
                (error: any) => { // error
                    console.log("oops! ----->", error);
                }
            );
            
        }, []);

        // Node dragging and edge updates during drag
        const handleNodeDrag = useCallback((event: React.MouseEvent, node: familyTreeNode) => {

            // Restrict Y-axis position during the drag (keep it fixed)
            const updatedPosition = {
                x: Math.ceil(node.position.x / 41) * 41,  // Update X-axis
                y: initialYRef.current // Keep Y-axis fixed
            };

            // Update draggedNodePositions immediately during the drag
            setDraggedNodePositions(prev => ({
                ...prev,
                [node.id]: updatedPosition // Real-time update for node position (X-axis only)
            }));

        }, [edges, nodes]);

        // Node draging release (finalize position)
        const handleNodeDragStop = useCallback((event: React.MouseEvent, node: familyTreeNode) => {
            const connectedEdge = edges.find(edge => (edge.target === node.id));

            let newHandleTo: string = node.data.targetHandle;

            if (connectedEdge && (node.data.targetHandle === 'left' || node.data.targetHandle == 'right')) {
                const connectedNodeId = connectedEdge.source === node.id ? connectedEdge.target : connectedEdge.source;
                const connectedNode = nodes.find(n => n.id === connectedNodeId);

                if (connectedNode) {
                    const isLeft = node.position.x < connectedNode.position.x;
                    const isRight = node.position.x > connectedNode.position.x;

                    if (isLeft) newHandleTo = 'right';
                    else if (isRight) newHandleTo = 'left';

                    console.log('Updated Handle Position:', newHandleTo);
                }
            }

            const formDataReposition = {
                posX: Math.ceil(node.position.x),
                posY: initialYRef.current,
                handleTo: newHandleTo
            };

            postFormData(
                commonData.token,
                `user/loggedIn/familyTree/${node.id}/data`,
                formDataReposition,
                (response: any) => console.log("Data sent!", response),
                (error: any) => console.log("Oops! Error:", error)
            );

            setRefreshCounter(c => c + 1);
        }, [edges, nodes]);

        const handleDeleteNode = (id: string) => {
            deleteRecord(
                commonData.token,
                `user/loggedIn/familyTree/${id}`,
                (response: any) => {
                    console.log("Node deleted");
                },
                (error: any) => { // error
    
                }
            );
        }

    // #endregion



    // ========================
    // MODAL HANDLING FUNCTIONS
    // ========================
    // #region

        const handleShow = () => {
            setShow(true);
            setFormStep(0);
        }

        const handleClose = () => {
            setShow(false);
            setFormStep(0);
        }

        const handleOpenModal = (modal: string) => {

            switch (modal) {
                case 'linkMemorial':
                    setLinkMemorialModalOpen(true);
                    break;

                case 'createMemorial':
                    setCreateMemorialModalOpen(true);
                    break;

                case 'editRelationship':
                    setEditRelationshipModalOpen(true);
                    break
            }       


            if (modal === 'linkMemorial') setLinkMemorialModalOpen(true);
            else if (modal === 'createMemorial') setCreateMemorialModalOpen(true);
        }

        const handleCloseDetailedNode = () => {
            setDetailedNodeActive(false);

            // setRefreshCounter((prev) => prev + 1); // Force refresh
            
            fetchRecords(commonData.token, `user/${endpoint}/familyTree`, processFamilyTreeData)
            .then(() => {
                // setRefreshCounter((prev) => prev + 1); // Force refresh
            })
            .catch((error) => {
                debug('fetchRecords error: ' + error, 'FamilyTree');
            });
        }

        const handleClickToTree = (endpoint: string) => {
            setEndpoint(endpoint);
        }

    // #endregion


    
    // =======================
    // FORM HANDLING FUNCTIONS
    // =======================
    // #region

        const handleFormChange = (e: React.ChangeEvent<any>) => {
            if (e.target.type === "text") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: e.target.value
                }));
            }
        }

        const handleClickBack = () => {
            if (formStep !== 0) {
                setFormStep(formStep - 1);
                console.log(formState)
            } else {
                handleClose();
            }        
        }

        const handleHasPassed = (passed: boolean) => {
            setHasPassed(passed);
    
            let proxyType: string = 'USER';
            if (passed) proxyType = 'MEMORIAL'
            
            setFormState((formState) => ({
                ...formState, 
                proxyType: proxyType
            }));

        }
    
        const handleConfirmRelative = () => {
            setModalSuccess(true);
    
            setTimeout(() => {
                setFormStep(0);
                setSelectedRelative('');
            }, 1000);
    
            setTimeout(() => {
                setModalSuccessAnimation('animate__animated animate__slideOutRight');
            }, 2000);
    
            setTimeout(() => {
                setModalSuccess(false);
                setModalSuccessAnimation('');
                setFormState(formData);

                if (hasPassed) setDeceasedRelativePopupOpen(true);
                else setLivingRelativePopupOpen(true);
                
            }, 3000);
        }

        const handleViewMemorial = () => {
            if (props.handleViewMemorial) props.handleViewMemorial();
            handleClose();
        }

    // #endregion



    // =========
    // POST DATA
    // =========
    // #region

        const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
            e.preventDefault();

            console.log('FORMSTATE------------------- ', formState);

            postFormData(
                commonData.token,
                `user/loggedIn/familyTree`,
                [formState],
                (response: any) => {
                    setDetailedNodeData(response[0]);

                    fetchRecords(commonData.token, `user/${endpoint}/familyTree`, processFamilyTreeData)
                    .then(() => {
                        handleRefresh();
                        handleConfirmRelative();
                    })
                    .catch((error) => {
                        debug('fetchRecords error: ' + error, 'FamilyTree');
                    });
                },
                (error: any) => { // error

                }
            );

            setTimeout(() => {
                setHasPassed(false);
            }, 3500);
        }

    // #endregion

    const handleRefresh = () => {
        setRefreshCounter(c => c+1);
        console.log(refreshCounter);
    }

    const handleToggleEdgeType = () => {
        if (edgeType === 'step') setEdgeType('simplebezier');
        else setEdgeType('step');
        handleRefresh();
    }

    return (
        <div className="test">
            <div className="btn fl-btn-dark w-100 mt-2" onClick={handleShow}>{createdNodes > 0 ? 'View' : 'Start'}</div>

            <Modal className="modal-deactivate-scroll" show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <div className={`btn-modal-back`} onClick={handleClickBack}>
                        <img src="/images/modal-back-chev.svg" />
                    </div>

                    {/* <p className="modal-title" onClick={handleToggleEdgeType}>{ownerNodeUser?.userId === commonData.userId ? 'Your Family' : ownerNodeUser?.lastName + ' Family'}</p> */}
                    <div className="d-flex flex-column gap-2">
                        <p className="modal-title" onClick={handleToggleEdgeType}>{ownerNodeUser?.email === commonData.email ? 'Your Family' : ownerNodeUser?.lastName + ' Family'}</p>
                        {ownerNodeUser?.email != commonData.email && <p className="modal-subtitle">Created by <span>{ownerNodeUser?.firstName}</span></p>}
                    </div>

                    <div className={`btn btn-modal-close`} onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body className="p-0">
                    <div className={`family-tree-wrapper form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')} justify-content-center`}>

                        <ReactFlow
                            nodes={nodes.map(node => ({
                                ...node,
                                position: draggedNodePositions[node.id] || node.position // Use updated position from draggedNodePositions
                            }))}
                            edges={edges}
                            onNodesChange={handleNodesChange}
                            onEdgesChange={onEdgesChange}
                            onNodeDragStart={handleNodeDragStart} // Capture Y position when dragging starts
                            onNodeDragStop={handleNodeDragStop}
                            onNodeDrag={handleNodeDrag}  // Track node movement during drag
                            onConnect={onConnect}
                            nodeTypes={nodeTypes}
                            fitView
                        >
                            <Background variant={BackgroundVariant.Dots} />
                            {/* <Controls /> */}
                        </ReactFlow>

                    </div>

                    <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')} p-4`}>
                        
                        {selectedRelative !== '' &&
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Tell us about {addRelativeData?.name}'s {selectedRelative}</p>
                                </div>

                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">First name</label>
                                        <input id="proxyFirstName" name="proxyFirstName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their first name" required />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">Last name</label>
                                        <input id="proxyLastName" name="proxyLastName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their last name" required />
                                    </div>

                                    <label htmlFor="email" className="form-label">Have they passed away?</label>
                                    <div className="d-flex gap-3">
                                        <div className="create-memorial-radio-wrapper d-flex align-items-center gap-3" onClick={() => handleHasPassed(true)}>
                                            <div className={`create-memorial-radio ${hasPassed && 'selected'}`}>
                                                <div className="radio-selected"></div>
                                            </div>
                                            <p>Yes</p>
                                        </div>

                                        <div className="create-memorial-radio-wrapper d-flex align-items-center gap-3" onClick={() => handleHasPassed(false)}>
                                            <div className={`create-memorial-radio ${!hasPassed && 'selected'}`}>
                                                <div className="radio-selected"></div>
                                            </div>
                                            <p>No</p>
                                        </div>
                                    </div>

                                    <button onClick={handlePost} className="btn fl-btn-modal-bottom button-absolute">Done</button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>

                {modalSuccess && 
                    <div className={`modal-success-div ${modalSuccessAnimation}`} style={{zIndex: 999}}>
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{formState.proxyFirstName} has been added</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                }

                <AddRelativePopup
                    handleSelectRelative={handleAddRelative}
                    posX={addRelativeData?.posX as number}
                    posY={addRelativeData?.posY as number}
                    relativeTo={addRelativeData?.name as string}
                    handleOpen={addRelativePopup}
                    closeModal={() => setAddRelativePopup(false)}
                />

                {detailedNodeData &&
                    <>
                        <DetailedNode
                            detailedNodeData={detailedNodeData}
                            id={detailedNodeData.treeNodeId}
                            user={detailedNodeUser}
                            memorial={detailedNodeMemorial}
                            firstName={detailedNodeData.proxyFirstName ? detailedNodeData.proxyFirstName : ''}
                            lastName={detailedNodeData.proxyLastName ? detailedNodeData.proxyLastName : ''}
                            relation={detailedNodeData.relationship}
                            posX={detailedNodeData.data.posX}
                            posY={detailedNodeData.data.posY}    
                            displayType={detailedNodeType}
                            handleOpen={detailedNodeActive}
                            handleExtentionClick={handleAddRelativePopup}
                            openMemorial={handleViewMemorial}
                            closeModal={handleCloseDetailedNode}
                            openModal={handleOpenModal}
                            deleteNode={handleDeleteNode}
                            pictureId={nodePictureId}
                        />
                        
                        <ModalCreateMemorial emptyState={false} fromFamilyTree={true} selectedNode={detailedNodeData} relation={detailedNodeData.relationship} showModal={createMemorialModalOpen} closeModal={() => {setCreateMemorialModalOpen(false); handleRefresh()}} link={""} />
                        <LinkMemorial selectedNode={detailedNodeData} showModal={linkMemorialModalOpen} closeModal={() => {setLinkMemorialModalOpen(false); handleRefresh()}} />
                        <EditRelationship selectedNode={detailedNodeData} availableConnections={availableConnections} showModal={editRelationshipModalOpen} closeModal={() => {setEditRelationshipModalOpen(false); handleRefresh()}} />
                    </>
                }
                            
                <DeceasedPlaceholderPopup relative={detailedNodeData?.proxyFirstName} showModal={deceasedRelativePopupOpen} openModal={handleOpenModal} />
                <LivingPlaceholderPopup id={detailedNodeData.treeNodeId} relative={detailedNodeData?.proxyFirstName} showModal={livingRelativePopupOpen} />            

            </Modal>
        </div>
    );
}