import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { postFormData } from "../../utils/AppUtil";
import { MemorialContext } from "../../MemorialContext";
import { familyTreeData, familyTreeNode, familyTreeUpdateType } from "../AppType";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";

type ModalProps = {
  selectedNode: familyTreeUpdateType;
  availableConnections?: familyTreeNode[];
  showModal: boolean;
  closeModal: () => void;
}

export const EditRelationship: React.FC<ModalProps> = (props) => {
  
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

  const [modalConnectionsUpdated, setModalConnectionsUpdated] = useState<boolean>(false);
  const [modalSuccessAnimation, setModalSuccessAnimation] = useState<string>("animate__animated animate__fadeIn animate__faster");

  const formData: familyTreeData = {
    posX: 0,
    posY: 0,
    fromTreeNodeId: [],
    handleFrom: "",
    handleTo: "",
  };

  const [formState, setFormState] = useState<familyTreeData>(formData);

  useEffect(() => {
    setFormState({
      posX: props.selectedNode.data.posX,
      posY: props.selectedNode.data.posY,
      fromTreeNodeId: props.selectedNode.data.fromTreeNodeId,
      handleFrom: props.selectedNode.data.handleFrom,
      handleTo: props.selectedNode.data.handleTo
    });

    console.log("props.selectedNode.data.fromTreeNodeId: ", props.selectedNode.data.fromTreeNodeId);
  }, [props.showModal]);

  const handleClose = () => {
    props.closeModal();
  };

  const handleNodeClick = (id: string) => {
    setFormState((formState) => {
      const newFromTreeNodeId = formState.fromTreeNodeId.includes(id)
        ? formState.fromTreeNodeId.filter((nodeId) => nodeId !== id) // Remove the id if it exists
        : [...formState.fromTreeNodeId, id]; // Add the id if it does not exist

      return {
        ...formState,
        fromTreeNodeId: newFromTreeNodeId
      };
    });
  };

  const handleClick = () => {
    console.log("FormState: ", formState);
    setModalConnectionsUpdated(true);
    handlePost();

    setTimeout(() => {
      handleClose();
    }, 2000);

    setTimeout(() => {
      setModalConnectionsUpdated(false);
      setModalSuccessAnimation("animate__animated animate__fadeIn animate__faster");
    }, 3000);
  };

  const handlePost = () => {
    postFormData(
    commonData.token,
      `user/loggedIn/familyTree/${props.selectedNode.treeNodeId}/data`,
      formState,
      (response: any) => {
        console.log(response);
      },
      (error: any) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <Modal show={props.showModal} fullscreen={true} onHide={handleClose}>
        <div
          className="btn btn-modal-close"
          onClick={handleClose}
          style={{zIndex: "99999"}}
        >
          <img src="/images/modal-close-x.svg" />
        </div>

        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center h-100 gap-5">
            <div className="available-connections-wrapper">
              {props.availableConnections?.map((node, index) => (
                <div key={index} id={node.id + ' --- ' + formState.fromTreeNodeId} className={`family-tree-node ${node.data.displayType}-node`} style={{position: "relative"}} onClick={() => handleNodeClick(node.id)}>
                  {formState.fromTreeNodeId && formState.fromTreeNodeId.includes(node.id) ? (
                    <img src="images/fl-tick-filled.svg" className="connection" />
                  ) : (
                    <div className="connection unconnected" />
                  )}

                  <div className="d-flex flex-column align-items-center gap-2">
                    <img src="/images/headshot-placeholder.svg" />

                    <div className="d-flex flex-column">
                      <p className="node-name tile-name">{node.data.firstName}</p>
                      <p className="node-name tile-name">{node.data.lastName}</p>
                    </div>
                  </div>

                  <p className="node-relation tile-relation">{node.data.relation}</p>
                </div>
              ))}
            </div>

            <div
              className={`family-tree-node ${props.selectedNode.proxyType}-node selected-tile`}
            >
              <div className="d-flex flex-column align-items-center gap-2">
                <img src="/images/headshot-placeholder.svg" />

                <div className="d-flex flex-column">
                  <p className="node-name tile-name">
                    {props.selectedNode.proxyFirstName}
                  </p>
                  <p className="node-name tile-name">
                    {props.selectedNode.proxyLastName}
                  </p>
                </div>
              </div>

              <p className="node-relation tile-relation">
                {props.selectedNode.relationship}
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-start">
          <div className="edit-relationship-text">
            <p>Edit relationships</p>
            <p>
              Please select the parent(s) you wish{" "}
              {props.selectedNode.proxyFirstName} to be linked to
            </p>
          </div>
          <button className="btn btn-modal-footer" onClick={handleClick}>
            Continue
          </button>
        </Modal.Footer>

        {modalConnectionsUpdated && (
          <div
            className={`modal-success-div ${modalSuccessAnimation}`}
            style={{ zIndex: 999 }}
          >
            <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
              <img src="/images/fl-login-tick.svg" />
              <div className="login-success-txt d-flex flex-column gap-1">
                <p>Connections Updated</p>
                <p></p>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
