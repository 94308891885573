import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { LifeEventCreateType, MemorialType, PictureGalleryType, PictureUploadType } from "../AppType";
import { Modal, Spinner } from "react-bootstrap";
import { ASSET_URL, fetchRecords, postFormData, postFormPictureFormData } from "../../utils/AppUtil";
import { ModalRememberDate } from "./ModalRememberDate";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';
import AvatarEditor from "react-avatar-editor";
import { useSpring } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";
import imageCompression from "browser-image-compression";
import { GoogleMap, useJsApiLoader, Marker, Libraries } from '@react-google-maps/api';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

const containerStyle = {
    width: '100vw',
    height: '100dvh',
}

const center = {
    lat: -29.80,
    lng: 24.84
}

const libraries: Libraries = ["places"];

const useGesture = createUseGesture([dragAction, pinchAction]);

interface ModalProps {
    onFileUpload: (uploadStatus: boolean, croppingStatus: boolean, count: number, total: number, error: Error | null) => void;
    onModalHide: () => void;
    onModalClose: () => void;
    changeEvent: () => void;
}

export const CreateLifeEvent: React.FC<ModalProps> = ({onFileUpload, onModalHide, onModalClose, changeEvent}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Date field refs
    const refEventDateDay = useRef<HTMLInputElement>(null!);
    const refEventDateMonth = useRef<HTMLInputElement>(null!);
    const refEventDateYear = useRef<HTMLInputElement>(null!);

    // Cropping & zooming refs
    const pinchRef = useRef<HTMLDivElement>(null!)
    const editorRef = useRef<AvatarEditor>(null!);
    const pictureInputRef = useRef<HTMLInputElement>(null!);
    const txtAreaRef = useRef<HTMLTextAreaElement>(null!);

    // Form progression
    const [formStep, setFormStep] = useState<number>(0);
    const [formStepDate, setFormStepDate] = useState<boolean>(false);
    const [formSuccess, setFormSuccess] = useState<string>("");
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);

    // Gallary & Editor states
    const [formStateImages, setFormStateImages] = useState<string[]>([]);
    const [newImage, setNewImage] = useState<File>();
    const [newImageIndex, setNewImageIndex] = useState<number>(0);

    const [newImageArray, setNewImageArray] = useState<File[]>([]);
    const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);
    const [uploadCount, setUploadCount] = useState<number>(0);
    const [zoom, setZoom] = useState<number>(1);
    const [addPhotos, setAddPhotos] = useState<boolean>(false);
    const [finalEdit, setFinalEdit] = useState<boolean>(false);

    const [imagesCropped, setImagesCropped] = useState<boolean>(false);
    const [autoCrop, setAutoCrop] = useState<boolean>(false);


    // Modal states
    const [animation, setAnimation] = useState<string>("animate__animated animate__flipInY");
    const [charactersRemaining, setCharactersRemaining] = useState<number>(240);
    const [charactersRemainingText, setCharactersRemainingText] = useState<string>(" characters max");
    const [btnNext, setBtnNext] = useState<boolean>(true);
    const [btnNextAnimation, setBtnNextAnimation] = useState<string>("");
    const [btnSkip, setBtnSkip] = useState<boolean>(false);
    const [btnSkipAnimation, setBtnSkipAnimation] = useState<string>("");
    const [formProgressHide, setFormProgressionHide] = useState<string>("");

    // Date validation states
    const [invalidDate, setInvalidDate] = useState<boolean>(false);
    const [invalidDay, setInvalidDay] = useState<boolean>(false);
    const [invalidMonth, setInvalidMonth] = useState<boolean>(false);
    const [invalidYear, setInvalidYear] = useState<boolean>(false);
    const invalidBorder: string = '3px solid #DC3545';

    // Map states
    const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
    const [mapZoom, setMapZoom] = useState<number>(13);
    const [searchOpen, setsearchOpen] = useState<boolean>(false);
    const [searchSelected, setsearchSelected] = useState<boolean>(false);
    const [mapClose, setMapClose] = useState<boolean>(false);
    const [address, setAddress] = useState("");
    
    const [canvasWidth, setCanvasWidth] = useState<number>(window.innerWidth);
    const [canvasHeight, setCanvasHeight] = useState<number>(window.innerWidth);

    // formState for loading image to gallery
    const initForm: PictureUploadType = {
        memorialId: memorialData.memorialId,
        file: null
    }
    const [formState, setFormState] = useState<PictureUploadType>(initForm);

    // formState for the life event being created
    const initFormLifeEvent: LifeEventCreateType = {
        title: "",
        message: "",
        eventDate: "",
        eventDateDay: "",
        eventDateMonth: "",
        eventDateYear: "",
        pictureIds: [],
        locations: []
    }
    const [formStateLifeEvent, setFormStateLifeEvent] = useState<LifeEventCreateType>(initFormLifeEvent);

    // Initial settings for touch-zoom functionality
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: zoom,
        rotateZ: 0,
    }));

    useEffect(() => {
        if (imagesCropped) {
            handleSaveEvent();
        }
    }, [imagesCropped])

    // useEffect to prevent default event behaviour for touch actions on component mount
    useEffect(() => {
        const handler = (e: Event) => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);
        document.addEventListener('gestureend', handler);

        // return runs cleanup on unmount to remove event listeners
        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
            document.removeEventListener('gestureend', handler);
        };
    }, []);

    // useEffect to get users current location for map
    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 setUserLocation({
    //                     lat: position.coords.latitude,
    //                     lng: position.coords.longitude
    //                 });
    //             },
    //             (error) => {
    //                 console.error('Error getting user location:', error);
    //             }
    //         );
    //     } else {
    //         console.error('Geolocation is not supported by this browser.');
    //     }
    // }, []);

    // Google Maps
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDX5iQx8lsEcck9KXBSRRLNh7oIwN3hF0U',
        libraries: libraries
    })

    // Load map and set map zoom
    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        setMap(map);
        map.setZoom(mapZoom)
    }, []);
    
    // map unmount
    const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
        setMap(null);
    }, []);

    // Add pin on map click
    // const handleMapClick = (event: google.maps.MapMouseEvent) => {
    //     if (event.latLng) {
    //         setMarkerPosition({
    //             lat: event.latLng.lat(),
    //             lng: event.latLng.lng()
    //         });

    //         // setFormState((formState) => ({
    //         //     ...formState, 
    //         //     locationLat: event.latLng.lat(),
    //         //     locationLng: event.latLng.lng(),
    //         //     locationDesc: address
    //         // }));
    //     }
    // };

    // select location and set marker
    const handleLocationSelect = async (value: any) => {
        const results = await geocodeByAddress(value);
        const coords = await getLatLng(results[0]);

        setAddress(value);
        setMarkerPosition({lat: coords.lat, lng: coords.lng});
        setUserLocation({lat: (coords.lat - 0.015), lng: coords.lng});
    }

    // Build image array
    const buildImageArray = (): React.ReactElement[] => {
        return newImageArray.map((file, index) => {
            const imageSrc = URL.createObjectURL(file);

            return <div key={`new-${index}`} className={`gallery-image-wrapper ${file === newImage ? 'selected' : ''}`} style={{backgroundImage: `url(${imageSrc})`}}>
                        <div className="gallery-image-buttons">
                            <div className="image-icon-left" onClick={() => handleClickCrop(file, index)}>
                                <img src="/images/icon-crop.svg" className="" width={16} height={16} />
                                <p>Crop</p>
                            </div>
                            <div className="image-icon-right" onClick={() => handleImageRemove(file)}>
                                <img src="/images/icon-trash.svg" className="" width={16} height={16} />
                            </div>
                        </div>
                   </div>
        });
    }

    // Build final image array
    const buildFinalImageArray = (): React.ReactElement[] => {
        return newImageArray.map((file, index) => {
            const imageSrc = URL.createObjectURL(file);

            return <div key={`new-${index}`} className={`gallery-image-wrapper final-screen ${file === newImage ? 'selected' : ''}`} style={{backgroundImage: `url(${imageSrc})`}}>                        
                        <img src="/images/fl-btn-cross.svg" className="event-image-remove" width={24} height={24} onClick={() => handleImageRemove(file)} />
                   </div>
        });
    }

    // remove image from array
    const handleImageRemove = (file: File) => {
        const itemIndex = newImageArray.indexOf(file);

        if (itemIndex > -1) {

            // Create a new array with the item removed
            const updatedArray = [...newImageArray];
            updatedArray.splice(itemIndex, 1);

            // Update the state with the new array
            setNewImageArray(updatedArray);
            setNewImage(undefined);
        }
    }

    // Scroll zoom for pc users
    const scaleStep = 0.1;
    document.getElementById('selectedImageContainer')?.addEventListener('wheel', (event) => {
        event.preventDefault();
        
        if (event.deltaY < 0) {
            setZoom(zoom + scaleStep);
        } else {
            setZoom(zoom - scaleStep);
        }
    });

    // Deactivate DOM zooming to allow uninterupted image zooming
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    // Touch-pinch-zoom actions
    useGesture(
        {
            onDrag: ({pinching, cancel, offset: [x, y], ...rest}) => {
                if (pinching) return cancel();
                api.start({ x, y });
            },
            onPinch: ({offset: [s], first}) => {
                if (first) {
                    const {width, height, x, y} = pinchRef.current!.getBoundingClientRect();
                    const tx = x + width / 2;
                    const ty = y + height / 2;
                    api.start({ x: -tx, y: -ty });
                }
                setZoom(s);
            },
        },
        {
            target: pinchRef,
            drag: {from: () => [style.x.get(), style.y.get()]},
            pinch: {scaleBounds: {min: 0.25, max: 5}, rubberband: false},
        }
    );

    // Validation (day)
    const validateDay = (value: string): string => {
        let numberValue: number = parseInt(value, 10);

        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidDay(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidDay(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidDay(false);
            return numberValue.toString();

        } else {
            setInvalidDay(true);
            return 'invalid';
        }
    }

    // Validation (month)
    const validateMonth = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidMonth(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidMonth(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidMonth(false);
            return numberValue.toString();

        } else {
            setInvalidMonth(true);
            return 'invalid';
        }
    }

    // Validation (year)
    const validateYear = (value: string): string => {

        let numberValue: number = parseInt(value, 10);

        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidYear(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidYear(false);
            return '000' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidYear(false);
            return '00' + numberValue.toString();

        } else if (numberValue >= 100 && numberValue < 1000) {
            setInvalidYear(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 1000 && numberValue < 10000) {
            setInvalidYear(false);
            return numberValue.toString();

        } else {
            setInvalidYear(true);
            return 'invalid';
        }
    }

    // Form change (non date fields)
    const handleFormChange = (e: React.ChangeEvent<any>) => {

        if (e.target.name === "message") {
            const value = e.target.value;

            if (value.length <= 240) {
                setCharactersRemaining(240 - value.length);

                if (value.length < 240) {
                    setCharactersRemainingText(" characters remaining");
                } else {
                    setCharactersRemainingText(" characters max");
                }

                setFormStateLifeEvent(() => ({
                    ...formStateLifeEvent,
                    [e.target.name]: e.target.value
                }));
            }
        }

        if (e.target.name === "title") {
            setFormStateLifeEvent(() => ({
                ...formStateLifeEvent,
                [e.target.name]: e.target.value
            }));
        }

        if (e.target.type === "file" && e.target.files) {
            const filesArray: File[] = Array.from(e.target.files);
            setNewImageArray(newImageArray => [...newImageArray, ...filesArray]);
        }
    }

    // Form change (date fields)
    const handleFormChangeDate = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {

            if (e.target.id === "eventDateYear") {
                setFormStateLifeEvent((formStateLifeEvent) => ({
                    ...formStateLifeEvent,
                    [e.target.id]: validateYear(e.target.value)
                }));

                if (e.target.value.length === 4 && !formStepDate) {
                    setFormStepDate(true);
                    setTimeout(() => {
                        if (refEventDateMonth.current) {
                            refEventDateMonth.current.focus();
                        }
                    }, 500);
                } else if (e.target.value.length === 4 && formStepDate) {
                    refEventDateMonth.current.focus();
                }
            }

            if (e.target.id === "eventDateMonth") {
                setFormStateLifeEvent((formStateLifeEvent) => ({
                    ...formStateLifeEvent,
                    [e.target.id]: validateMonth(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refEventDateDay.current.focus();
                }
            }

            if (e.target.id === "eventDateDay") {
                setFormStateLifeEvent((formStateLifeEvent) => ({
                    ...formStateLifeEvent,
                    [e.target.id]: validateDay(e.target.value)
                }));
            }
        }
    }

    // Truncate title
    const truncate = (text: string) => {
        return text.length > 30 ? text.substring(0, 30) + "..." : text;
    }

    // Skip adding media - go to final screen
    const handleSkip = () => {
        setAddPhotos(false);
        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
            setBtnSkip(false);
            setFormStep(5);
        }, 250);
    }

    // Add images media option selected
    const handleAddPhotos = () => {
        setAddPhotos(true);
        setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
        setFormStep(4);

        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setBtnSkip(false);
        }, 500);
    }
    
    // TODO - Scan photos media option selected
    const handleScanPhotos = () => {

    }

    // Add location option selected
    const handleAddLocation = () => {
        setFormStep(6);
    }

    // Add selected location to life event
    const handleLocationAdded = () => {
        setFormStateLifeEvent((formStateLifeEvent) => ({
            ...formStateLifeEvent,
            locations: [
              ...formStateLifeEvent.locations,
              {
                latitude: markerPosition.lat,
                longitude: markerPosition.lng,
                description: address
              }
            ]
        }));
        handleClickBack();
    }

    // activate image input
    const handleAddImage = () => {
        setAddPhotos(true);

        if (formStep === 5) {
            setFormStep(formStep - 1);
        }
        
        pictureInputRef.current.click();
    }

    // Activate final screen input fields
    const handleFinalEdit = () => {
        setFinalEdit(c => !c);

        if (!finalEdit) {
            setTimeout(() => {
                txtAreaRef.current.style.removeProperty('height');
                txtAreaRef.current.style.height = (txtAreaRef.current.scrollHeight+2) + 'px';
            }, 0);
        }
    }

    // Open & close search
    const handleSearchOpen = () => {
        setsearchOpen(c => !c);
    }

    // Open search when focusing on input field
    const handleSearchInput = () => {
        if (!searchOpen) {
            setsearchOpen(true);
            setsearchSelected(false);
        }
    }

    const handleSearchSelect = () => {
        if (searchOpen) {
            setsearchOpen(false);
            setTimeout(() => {
                setsearchSelected(true);
            }, 1000);
        }
    }

    // Navigate form backwards
    const handleClickBack = () => {
        if (formStep === 3) {
            setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnSkip(false);
                setBtnNext(true);
                setBtnNextAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
            setFormStep(formStep - 1);

        } else if (formStep === 4) {

            if (newImage) {
                setNewImage(undefined);
            } else {
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
                setBtnSkip(true);
                setFormProgressionHide("");
                setFormStep(formStep - 1);
            }

        } else if (formStep === 5) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setAddPhotos(false);
            setFormProgressionHide("");

            setTimeout(() => {
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
                setBtnNext(false);
                setBtnSkip(true);
            }, 500);
            setFormStep(3);
        } else if (formStep === 6) {
            setMapClose(true);
            setTimeout(() => {
                setFormStep(formStep - 1);
                setMapClose(false);
            }, 700);
        } else {
            setFormStep(formStep - 1);
        }

        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }

    // Navigate form forwards
    const handleClickNext = () => {

        if (formStep === 1 && !formStepDate) {
            
            // if (formStateLifeEvent.eventDateYear === "") {
            //     setInvalidYear(true);
            //     setInvalidDate(true);
            // } else {
            {
                setFormStepDate(true);
            }
        } else if (formStep === 1 && formStepDate) {
            if (formStateLifeEvent.eventDateDay === "") {
                setInvalidDay(true);
                setInvalidDate(true);

            } else if (formStateLifeEvent.eventDateMonth === "") {
                setInvalidMonth(true);
                setInvalidDate(true);
                
            } else if (formStateLifeEvent.eventDateYear === "") {
                setInvalidYear(true);
                setInvalidDate(true);

            } else if (formStateLifeEvent.eventDateDay != 'invalid' && formStateLifeEvent.eventDateMonth != 'invalid' && formStateLifeEvent.eventDateYear != 'invalid') {
                setFormStateLifeEvent((formStateLifeEvent) => ({
                    ...formStateLifeEvent, 
                    eventDate: formStateLifeEvent.eventDateYear + "-" + formStateLifeEvent.eventDateMonth + "-" + formStateLifeEvent.eventDateDay
                }));

                setFormStep(formStep + 1);
            } else {
                setInvalidDate(true);
            }

        } else if (formStep === 2) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnNext(false);
                setBtnSkip(true);
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
            setFormStep(formStep + 1);

        } else if (formStep === 4) {
            // handleCropAllImages();
            setFormStep(formStep + 1);
        } else {
            setFormStep(formStep + 1);
        }
    }

    // Open croping for selected image
    const handleClickCrop = async (file: File, index: number): Promise<void> => {
        setZoom(1);
        setNewImage(file);
        setActiveImageIndex(index);
    };

    const handleCrop = async (): Promise<void> => {
        if (activeImageIndex === null) {
            console.error("No active image selected for cropping.");
            return;
        }
    
        const canvasImage: HTMLCanvasElement = editorRef.current.getImage();
    
        return new Promise((resolve, reject) => {
            canvasImage.toBlob((blob: Blob | null) => {
                if (!blob) {
                    reject(new Error("Failed to convert canvas to Blob"));
                    return;
                }
    
                // Replace the file at the activeImageIndex
                setNewImageArray(prevArray => {
                    if (activeImageIndex >= 0 && activeImageIndex < prevArray.length) {
                        const updatedArray = [...prevArray];
                        updatedArray[activeImageIndex] = new File([blob], prevArray[activeImageIndex].name, { type: 'image/jpeg' });
                        return updatedArray;
                    } else {
                        console.error("Invalid active image index.");
                        return prevArray;
                    }
                });
    
                resolve();
                setNewImage(undefined);
            });
        });
    };

    const handleCropAllImages = async (): Promise<void> => {
        for (let i = 0; i < newImageArray.length; i++) {
            const file = newImageArray[i];
            console.log('Cropping image ' + i);
    
            // Trigger some loading or progress indicator (if any)
            onFileUpload(true, true, i + 1, newImageArray.length, null);
    
            try {
                // Load the File into an HTMLImageElement
                const croppedBlob = await new Promise<Blob>((resolve, reject) => {
                    const img = new Image();
                    const url = URL.createObjectURL(file);
                    img.src = url;
    
                    img.onload = async () => {
                        try {
                            const croppedBlob = await cropImageToCanvas(img, 1024, 1024); // Example crop size
                            resolve(croppedBlob);
                        } catch (error) {
                            reject(error);
                        } finally {
                            URL.revokeObjectURL(url); // Clean up the object URL
                        }
                    };
    
                    img.onerror = () => {
                        reject(new Error('Failed to load image for cropping'));
                    };
                });
    
                if (croppedBlob) {
                    console.log('Cropped image ' + i);
    
                    // Convert the Blob to a File and store in the array
                    const croppedFile = new File([croppedBlob], file.name, { type: 'image/jpeg' });
                    setNewImageArray(prevArray => {
                        const updatedArray = [...prevArray];
                        updatedArray[i] = croppedFile;
                        return updatedArray;
                    });
                }
            } catch (error) {
                console.error('Error cropping image ' + i, error);
            }
        }
    };
    
    const cropImageToCanvas = (image: HTMLImageElement, width: number, height: number): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
    
            if (!ctx) {
                reject(new Error("Failed to create 2D context"));
                return;
            }
    
            // Set canvas dimensions (square)
            const canvasWidth = width;
            const canvasHeight = height;
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
    
            // Get the aspect ratios of the image and the canvas
            const imageAspectRatio = image.width / image.height;
            const canvasAspectRatio = canvasWidth / canvasHeight;
    
            let renderWidth, renderHeight;
            let offsetX = 0, offsetY = 0;
    
            // Fill the canvas while maintaining aspect ratio, possibly cropping the image
            if (imageAspectRatio > canvasAspectRatio) {
                // Image is wider than the canvas (crop the sides)
                renderHeight = canvasHeight;
                renderWidth = image.width * (canvasHeight / image.height);  // Scale width proportionally
                offsetX = (canvasWidth - renderWidth) / 2; // Center horizontally
            } else {
                // Image is taller than the canvas (crop the top and bottom)
                renderWidth = canvasWidth;
                renderHeight = image.height * (canvasWidth / image.width);  // Scale height proportionally
                offsetY = (canvasHeight - renderHeight) / 2; // Center vertically
            }
    
            // Clear the canvas
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    
            // Draw the scaled image, cropping to fit the canvas
            ctx.drawImage(image, offsetX, offsetY, renderWidth, renderHeight);
    
            // Convert the canvas to a Blob
            canvas.toBlob((blob: Blob | null) => {
                if (!blob) {
                    reject(new Error("Failed to convert canvas to Blob"));
                    return;
                }
                resolve(blob);
            }, 'image/jpeg');
        });
    };

    const handleImageReady = (event: Event) => {
        console.log('Image is ready:', event);
    
            // Example: You can access the editor and get image properties here
            if (editorRef.current) {
                const canvas = editorRef.current.getImage();
                console.log('Canvas image:', canvas);
                console.log('Canvas image index:', Number(canvas.id));

            }
    };

    // Handle image compression
    const handleCompression = async (file: File): Promise<Blob> => {
        console.log('originalFile instanceof Blob', file instanceof Blob);
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };

        try {

            // Increment progress count
            setUploadCount(prevCount => {
                const newCount = prevCount + 1;
                onFileUpload(true, false, newCount, newImageArray.length, null); // Update parent with new count
                return newCount;
            });

            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

            // Simulate delay
            // await new Promise(resolve => setTimeout(resolve, 1000));

            return compressedFile;
        } catch (error: any) {
            console.log(error.message);
            throw error;
        }
    };

    const handlePostPicture = async (compressedFile: Blob, fileName: string): Promise<string | null> => {
        try {
            const formData = new FormData();
            formData.set("memorialId", formState.memorialId);
            formData.set("file", compressedFile);
    
            return new Promise((resolve, reject) => {
                postFormPictureFormData(
                    commonData.token,
                    'picture',
                    formData,
                    (responseData: any) => {
                        console.log("Multi-post Success", responseData);
                        const { pictureId } = responseData;
                        resolve(pictureId);
                    },
                    (error: any) => {
                        console.log("Multi-post Fail", error);
                        reject(null);
                    }
                );
            });
        } catch (error) {
            console.error('Error in handlePostPicture:', error);
            return null;
        }
    };

    const handleClickSave = async () => {

        setFormStep(7);
        setAutoCrop(true);

        setTimeout(() => {
            onModalHide();
        }, 2000);
        
        await handleCropAllImages();

        setImagesCropped(true);
        console.log('All images cropped successfully.');
    }
    
    const handleSaveEvent = async (): Promise<void> => {
        try {

            // Use a local variable to collect picture IDs
            const collectedPictureIds: string[] = [];
    
            // Process all images
            for (const file of newImageArray) {

                const compressedImage = await handleCompression(file);
                console.log('Compression completed successfully.', compressedImage);
    
                const pictureId = await handlePostPicture(compressedImage, file.name);
                if (pictureId) {
                    collectedPictureIds.push(pictureId);
                }
            }
            
            console.log("collectedPictureIds", collectedPictureIds);
    
            // After all images are processed, update the state with the collected picture IDs
            setFormStateImages(collectedPictureIds);
            setFormStateLifeEvent((prevState) => ({
                ...prevState,
                pictureIds: collectedPictureIds,
            }));
    
            // Call handlePost to submit form data with the latest pictureIds
            await handlePost(collectedPictureIds);
    
            onFileUpload(false, false, newImageArray.length, newImageArray.length, null);
        } catch (error: any) {
            // onFileUpload(false, false, newImageArray.length, newImageArray.length, error);
            console.error('An error occurred:', error);
        }
    };
    
    // Post form data to create Life Event
    const handlePost = async (pictureIds: string[]): Promise<void> => {
        try {
            
            // Use the passed pictureIds
            const formData = {
                ...formStateLifeEvent,
                pictureIds: pictureIds,
            };
    
            await postFormData(
                commonData.token,
                `memorial/${memorialData.memorialId}/life_event`,
                formData,
                (response: any) => {
                    setFormSuccess("");
                    onFileUpload(false, false, newImageArray.length, newImageArray.length, null);
                },
                (error: any) => { // error
                    setFormSuccess(error);
                    onFileUpload(false, false, newImageArray.length, newImageArray.length, error);
                    console.log('handlepost error');
                }
            );
    
            console.log('Form data posted with pictureIds:', pictureIds);
    
        } catch (error) {
            console.error('An error occurred while posting form data:', error);
            setFormSuccess('Failed to submit form data.');
        }
    };

    const inputs = document.querySelectorAll('textarea[data-expandable]');

    if (inputs) {
        inputs.forEach((textarea, i) => {
            textarea.addEventListener('input', function(e) {

                const textAreaInput = e.target as HTMLTextAreaElement;
                textAreaInput.style.removeProperty('height');
                textAreaInput.style.height = (textAreaInput.scrollHeight+2) + 'px';
            });
        });
    }
    

    return (
        <>
            <input ref={pictureInputRef} id="file" name="file" type="file" accept="image/*" onChange={handleFormChange} style={{display: "none"}} multiple />

            <div className="form-content h-100" style={{backgroundColor: "#00222F", zIndex: "999"}}>

                {formStep !== 7 &&
                    <div className="modal-header-bg">
                        
                        {formStep !== 0 &&
                            <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                                <img src="/images/modal-back-chev.svg" />
                            </div>
                        }

                        {formStep === 4 && addPhotos ?
                            <p className="modal-title">Add photos</p>
                            :
                            <p className="modal-title">New Life Event</p>
                        }

                        <div className="btn btn-modal-close" onClick={onModalClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                    </div>
                }

                <div className={`form-progress form-progress-absolute ${formProgressHide}`} style={{top: "100px"}}>
                    <img src={`/images/fl-progress-book.svg`} className={`form-progress-current form-progress-${formStep}`} />
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <p className="modal-heading mb-4">What’s this life event called?</p>
                    
                    <div className="login-form">
                        <div className="mb-4">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input id="title" name="title" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter a short title for this event" />
                        </div>
                    </div>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <div className="d-flex flex-column gap-2 mb-4">
                        <p className="modal-heading">When did <i>“{formStateLifeEvent.title}”</i> happen?</p>
                    </div>
                    
                    <div className="login-form">
                        <div className="d-flex gap-3 mb-4">
                            
                            <div className="row w-100">
                                <div className="form-group col-4">
                                    <label htmlFor="eventDateYear" className="form-label">Year*</label>
                                    <input ref={refEventDateYear} id="eventDateYear" name="eventDateYear" type="text" maxLength={4} onChange={handleFormChangeDate} className="form-control" placeholder="YYYY" style={{border: invalidYear ? invalidBorder : ""}} />
                                </div>
                                
                                {formStepDate && 
                                    <>
                                        <div className="form-group col-4 animate__animated animate__fadeInRight">
                                            <label htmlFor="eventDateMonth" className="form-label">Month</label>
                                            <input ref={refEventDateMonth} id="eventDateMonth" name="eventDateMonth" type="text" maxLength={2} onChange={handleFormChangeDate} className="form-control" placeholder="MM" style={{border: invalidMonth ? invalidBorder : ""}} />
                                        </div>

                                        <div className="form-group col-4 animate__animated animate__fadeInRight">
                                            <label htmlFor="eventDateDay" className="form-label">Day</label>
                                            <input ref={refEventDateDay} id="eventDateDay" name="eventDateDay" type="text" maxLength={2} onChange={handleFormChangeDate} className="form-control" placeholder="DD" style={{border: invalidDay ? invalidBorder : ""}} />
                                        </div>
                                    </>
                                }

                                {invalidDate &&
                                    <div className="invalid-form-entry animate__animated animate__zoomIn">Please enter a valid date</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep > 2 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <div className="form-floating">
                        <textarea data-expandable id="message" name="message" value={formStateLifeEvent.message} onChange={handleFormChange} maxLength={240} className="form-control memory-message-input" placeholder="Write a description of the life event" />
                        <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                    </div>
                </div>
                
                <div className={`form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep > 3 ? 'prev' : 'next')}`} style={{padding: "170px 16px 16px"}}>
                    <p className="modal-heading mb-4">Upload some photos or videos</p>

                    <div className="d-flex flex-column">
                        <div className="image-upload-menu-item" onClick={handleAddPhotos}>
                            <div className="d-flex gap-3">
                                <img src="images/camera-icon.svg" width={24} />
                                <p className="event-add-assets">Add photos & videos</p>
                            </div>
                        </div>

                        <div className="image-upload-menu-item" onClick={handleScanPhotos}>
                            <div className="d-flex gap-3">
                                <img src="images/scan-icon.svg" width={24} />
                                <p className="event-add-assets">Scan a photo</p>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 4 ? 'current' : (formStep > 4 ? 'prev' : 'next')}`} style={{padding: "62px 16px 16px"}}>

                    {addPhotos === true &&
                        <>
                            {newImageArray.length !== 0 ?
                                <>
                                    {newImage ?
                                        <>
                                            <div className="d-flex justify-content-center" style={{width: "100vw", height: "100vw", marginLeft: "-16px"}}>
                                                <div className="image-canvas-grid" />

                                                <div ref={pinchRef} id="deactivateZoom" className="memorial-img-crop-wrapper d-flex justify-content-center align-items-center">
                                                    <div id="selectedImageContainer" className="memorial-img-crop-preview">
                                                        {newImage &&
                                                            <AvatarEditor
                                                                key={newImageIndex}
                                                                
                                                                ref={editorRef}
                                                                image={newImage}
                                                                border={0}
                                                                borderRadius={0}
                                                                scale={zoom}
                                                                rotate={0}
                                                                width={canvasWidth}
                                                                height={canvasHeight}
                                                                disableHiDPIScaling={true}
                                                                backgroundColor={'transparent'}
                                                                onImageReady={autoCrop ? handleImageReady : undefined}
                                                                style={{
                                                                    transform: `translate(${style.x}px, ${style.y}px) scale(${style.scale}) rotate(${style.rotateZ}deg)`,
                                                                    touchAction: "pinch-zoom",

                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-center gap-2 p-3">
                                                <img src="/images/pinch-zoom.svg" />
                                                <p className="pinch-zoom-text">Pinch & zoom to crop</p>
                                            </div>

                                            <div className="fl-btn-group-modal-bottom">
                                                <button className="btn fl-btn-modal-bottom" onClick={handleCrop} type="button">Save changes</button>
                                                <div className="d-flex justify-content-center w-100 mt-3">
                                                    <p className="image-crop-cancel" onClick={() => setNewImage(undefined)}>Cancel</p>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="event-photo-uploading">
                                            <div className="image-gallery">
                                                {buildImageArray()}
                                                <div className="event-additional-images" onClick={handleAddImage} style={{cursor: 'pointer'}}><img src="/images/fl-plus-lg.svg" /></div>
                                            </div>
                                            <button className="btn fl-btn-modal-bottom button-absolute" onClick={handleClickNext} type="button">Done</button>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div className="add-photo-btn" onClick={handleAddImage}>
                                            <img src="/images/fl-plus-lg.svg" width={32} height={32} />
                                            <p>Click to upload photos<br/>and videos</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>

                <div className={`form-screen form-content form-content-${(formStep === 5 || formStep === 6) ? 'current' : (formStep > 5 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                    <div className="d-flex justify-content-between gap-3">
                        {finalEdit ?
                            <div className="login-form w-100">
                                <input
                                    id="title"
                                    name="title"
                                    type="text"
                                    onChange={handleFormChange}
                                    className="form-control"
                                    value={formStateLifeEvent.title}
                                    placeholder="Enter a short title for this event"
                                    style={{height: '76px', fontSize: '1.5rem'}}
                                />
                            </div>:
                            <p className="modal-heading w-100">{truncate(formStateLifeEvent.title)}</p>
                        }
                        
                        <img src="/images/fl-btn-pen.svg" onClick={handleFinalEdit} width={32} height={32} style={{cursor: 'pointer'}} />
                    </div>

                    <p className="life-event-create-date" style={{height: "24px"}}>{formStateLifeEvent.eventDateDay}&nbsp;
                        {(formStateLifeEvent.eventDateMonth === "1" || formStateLifeEvent.eventDateMonth === "01") && "January"}
                        {(formStateLifeEvent.eventDateMonth === "2" || formStateLifeEvent.eventDateMonth === "02") && "February"}
                        {(formStateLifeEvent.eventDateMonth === "3" || formStateLifeEvent.eventDateMonth === "03") && "March"}
                        {(formStateLifeEvent.eventDateMonth === "4" || formStateLifeEvent.eventDateMonth === "04") && "April"}
                        {(formStateLifeEvent.eventDateMonth === "5" || formStateLifeEvent.eventDateMonth === "05") && "May"}
                        {(formStateLifeEvent.eventDateMonth === "6" || formStateLifeEvent.eventDateMonth === "06") && "June"}
                        {(formStateLifeEvent.eventDateMonth === "7" || formStateLifeEvent.eventDateMonth === "07") && "July"}
                        {(formStateLifeEvent.eventDateMonth === "8" || formStateLifeEvent.eventDateMonth === "08") && "August"}
                        {(formStateLifeEvent.eventDateMonth === "9" || formStateLifeEvent.eventDateMonth === "09") && "September"}
                        {formStateLifeEvent.eventDateMonth === "10" && "October"}
                        {formStateLifeEvent.eventDateMonth === "11" && "November"}
                        {formStateLifeEvent.eventDateMonth === "12" && "December"}&nbsp;
                        {formStateLifeEvent.eventDateYear}
                    </p>

                    <div className="event-images-preview no-scrollbar gap-4">
                        {buildFinalImageArray()}
                        <div className="event-additional-images final-screen" onClick={handleAddImage} style={{cursor: 'pointer'}}><img src="/images/fl-plus-lg.svg" /></div>
                    </div>

                    {finalEdit ?
                        <div className="form-floating">
                            <textarea
                                ref={txtAreaRef}
                                data-expandable
                                id="message"
                                name="message"
                                value={formStateLifeEvent.message}
                                onChange={handleFormChange}
                                maxLength={240}
                                className="form-control memory-message-input final-edit"
                                placeholder="Write a description of the memory"
                                style={{backgroundColor: 'rgba(255, 255, 255, 0.0509803922)', border: '2px solid #40626F'}}
                            />
                            <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                        </div>:
                        <p className="memory-preview-message">{formStateLifeEvent.message}</p>
                    }

                    {formStateLifeEvent.locations.length === 0 &&
                        <div className="event-additional-item" onClick={handleAddLocation}>
                            <div className="d-flex gap-3">
                                <img src="images/fl-map-pin-24.svg" width={24} />
                                <p className="event-add-assets">Add a location</p>
                            </div>
                        </div>
                    }
                    
                    {formStateLifeEvent.locations.length !== 0 &&
                        <div className="event-locations">
                            <>
                                {formStateLifeEvent.locations.map((location, index) => (
                                    <div key={index} className="life-event-location-pill d-flex gap-2">
                                        <img src="/images/fl-map-pin.svg" />
                                        <p>{location.description}</p>
                                    </div>
                                ))}
                                <div className="life-event-location-pill d-flex gap-2" onClick={handleAddLocation}>
                                    <img src="/images/fl-map-pin.svg" />
                                    <p>Add location</p>
                                </div>
                            </>
                        </div>
                    }
                    
                    <button className={`btn fl-btn-modal-bottom`} onClick={handleClickSave} style={{position: "absolute", bottom: 16, width: "calc(100% - 32px)", zIndex: "999"}}>Save Event</button>
                </div>

                <div className={`form-screen form-content form-content-${formStep === 7 ? 'current' : (formStep > 7 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>

                    <div className="d-flex flex-column h-100">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="modal-success-txt">
                                <p><b>Life Event added</b></p>
                            </div>
                        </div>
                    </div>

                </div>

                {btnNext &&
                    <div className="fl-btn-group-modal-bottom">
                        <button className={`btn fl-btn-modal-bottom ${btnNextAnimation}`} onClick={handleClickNext}>Next</button>
                        {formStep === 1 &&
                            <div className="d-flex justify-content-center w-100">
                                <ModalRememberDate onButtonClick={changeEvent} />
                            </div>
                        }
                    </div>
                }
                {btnSkip &&
                    <>
                        <button className={`btn-modal-footer-skip ${btnSkipAnimation}`} onClick={handleSkip}>Skip</button>
                    </>
                }

                {/* {isLoaded && formStep === 6 ?
                    <>
                        <div className={`full-screen-div animate__animated animate__slide${mapClose ? 'Out' : 'In'}Right animate__fast`} style={{marginTop: "-50px"}}>
                            <GoogleMap 
                                mapContainerStyle={containerStyle}
                                center={userLocation || center}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                options={{disableDefaultUI: true,}}
                            >
                                {markerPosition?.lat !== 0 && markerPosition?.lng !== 0 && (
                                    <Marker position={markerPosition} />
                                )}
                            </GoogleMap>

                        </div>

                        <div className={`map-search ${searchOpen && "active"} pt-0 animate__animated animate__slide${mapClose ? 'OutDown' : 'InUp'} `} style={{height: 'auto'}}>
                            <div className="d-flex justify-content-center align-items-center" onClick={handleSearchOpen}>
                                <div className="modal-account-drawer-close"></div>
                            </div>

                            <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleLocationSelect}>
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div key={getSuggestionItemProps.name}>
                                    <input {...getInputProps({ placeholder: 'Search places', className: 'location-search-input',})} onClick={handleSearchInput} />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div className="autocomplete-results">Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                            
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                            ? { cursor: 'pointer' }
                                            : {  };

                                            return (
                                                <div {...getSuggestionItemProps(suggestion, {className,style})}>
                                                    <div className="d-flex justify-content-between gap-2 py-1">
                                                        <img src="/images/fl-map-pin-24.svg"  />
                                                        <span className="autocomplete-results w-100" onClick={handleSearchSelect} style={{overflow: "hidden"}}>
                                                            <b>{suggestion.formattedSuggestion.mainText}</b>
                                                            <br/>
                                                            <span style={{whiteSpace: "nowrap"}}>{suggestion.formattedSuggestion.secondaryText}</span>
                                                        </span>
                                                        <img src="/images/arrow-up-left.svg" />
                                                    </div>
                                                    <hr className="autocomplete-hr" />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>

                            {!searchOpen &&
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-modal-footer" onClick={handleLocationAdded}>Done</button>
                                </div>
                            }
                        </div>
                    </>
                    : <></>
                } */}
            </div>
        </>
    );
}





