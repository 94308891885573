import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { debug } from './utils/AppUtil';
import { GetUserType } from './components/AppType';

// Define types for your context data
interface UserContextData {
  userData: GetUserType;
  setUserData: React.Dispatch<React.SetStateAction<GetUserType>>;
}

// Create a context object
export const UserContext = createContext<UserContextData | undefined>(undefined);

// Create a provider component
export const UserProvider: React.FC<{children: ReactNode}> = (props) => {
  	const userDefault: GetUserType = {
			userId: '',
			firstName: '',
			lastName: '',
			mobileNumber: '',
			gender: '',
			email: '',
			password: '',
			dateOfBirth: '',
			profilePicture: {
				pictureId: '',
				isVideo: false
			}
		}

  const [userData, setUserData] = useState<GetUserType>(userDefault); // initial data

  useEffect(() => {
    debug("User Context", JSON.stringify(userData));
  }, [userData]);

  return (
	<UserContext.Provider value={{userData, setUserData}}>
		{props.children}
	</UserContext.Provider>
  );
};

