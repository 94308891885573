import 'animate.css';
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";

export const Discover: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal handlers
    const [noSuggestions, setNoSuggestions] = useState<boolean>(false);
    const [noExisting, setNoExisting] = useState<boolean>(false);


    return (
        <>
            <div style={{height: '100vh'}}>
                <div className="search-input input-group m-3">
                    <input type="text" className="form-control" placeholder="Search for a memorial" />
                    <span className="input-group-text">
                        <img src="/images/fl-icon-search.svg" width={16} height={16} />
                    </span>
                </div>
            </div>
        </> 

    );
}