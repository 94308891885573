import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { Modal } from "react-bootstrap";
import { useSpring} from '@react-spring/web';
import { useTranslation } from "react-i18next";
import { ASSET_URL, debug, fetchRecords, postFormData } from "../../utils/AppUtil";
import { MemorialUserType, MemorialUserRoleType } from "../AppType";
import 'animate.css';
import { UserContext } from "../../UserContext";

type ModalProps = {
    type: string;
    from: string;
    modalClose?: () => void;
}

export const ModalShareContent: React.FC<ModalProps> = ({modalClose, ...props}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // User data from UserContext
    const { userData, setUserData } = useContext(UserContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal handlers
    const [show, setShow] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const [formState, setFormState] = useState({
        memorialId: '',
        role: ''
    });

    useEffect(() => {
        setFormState({
            memorialId: memorialData.memorialId,
            role: props.type
        });

        if (props.type === 'CONTRIBUTOR') setIsChecked(true);
        else setIsChecked(false);
        setIsLoading(false);
    }, [show]);
    
    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (showModal: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => {
                if (modalRef.current && modalRef.current.contains(e.target as Node)) {
                    return; // Allow scrolling inside the modal
                }

                e.preventDefault(); // Prevent scrolling outside the modal (i.e., body scroll)
            };

            if (showModal) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchmove', preventScroll);
            }

            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [showModal, modalRef]);
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    let profilePictureSrc = "images/fl-avatar-min.svg";

    if (memorialData.memorialPictureId) {
        profilePictureSrc = `${ASSET_URL}/public/picture/${memorialData.memorialPictureId}`;
    }

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) setIsChecked(true);
        else setIsChecked(false);

        if (e.target.type === 'checkbox') {
            if (isChecked) {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: 'CONTRIBUTOR'
                }));
            } else {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: 'VIEWER'
                }));
            }
        }
    }

    // Open modal
    const handleShow = () => {
        setShow(true);
    }

    // Close modal
    const handleClose = () => {
        if (modalClose) modalClose();
        setShow(false);
    }

    const handleInviteUser = (type: string) => {
        setIsLoading(true);

        postFormData(
            commonData.token, 
            'invite/memorialRole',
            formState,
            (response: string) => {
                console.log('response - inviteId: ', response);
                handleOpenInviteTray(response, type);
            },
            (error: any) => { // error
                console.log('error: ', error);
            }
        );
    }

    const handleOpenInviteTray = (inviteId: string, role: string) => {
        if (navigator.share) {
            navigator.share({
                title: 'Foreverloved',
                text: `${userData.firstName} ${userData.lastName} has invited you to be a ${role} on ${memorialData.firstName} ${memorialData.lastName}'s memorial`,
                url: `https://mvp8.foreverloved.com/login?id=${inviteId}&userFirstName=${userData.firstName}&userLastName=${userData.lastName}&userImage=${userData.profilePicture.pictureId}&memorialFirstName=${memorialData.firstName}&memorialLastName=${memorialData.lastName}&type=${role}`,
            })
            .then()
            .catch((error) => console.error('Error sharing content:', error));
        } else {
            console.log('Web Share API is not supported on this device.');
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    return (
        <>
            {props.from === 'memorialPage' ?
                <div className="btn-wrapper" onClick={handleShow}>
                    <img src="/images/fl-share-thick.svg" alt="" />
                </div>
                :
                props.type === 'CONTRIBUTOR' ?
                <div className="memorial-account-link" onClick={handleShow}>
                    <img src="/images/fl-icon-send.svg" width={24} height={24} />
                    <p>Invite collaborators</p>
                </div>
                :
                <div className="memorial-account-link" onClick={handleShow}>
                    <img src="/images/fl-share.svg" width={24} height={24} />
                    <p>Share memorial</p>
                </div>
            }

            <Modal show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <p className="modal-title">Share memorial</p>
                    <div className="btn btn-modal-close" onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body ref={modalRef} className="p-3 pt-5">
                    <div className="send-link-wrapper">
                        <img src={profilePictureSrc} />
                        <p className="memorial-name">{memorialData.firstName} {memorialData.lastName}</p>
                        <p className="memorial-dates">{memorialData.birthYear} - {memorialData.deathYear}</p>

                        <div className="reoccuring-donation-switch form-check form-switch d-flex justify-content-between">
                            <label className="form-check-label" htmlFor="role">Allow those invited to collaborate on this memorial</label>
                            <input
                                className="form-check-input m-0"
                                type="checkbox"
                                role="switch"
                                id="role"
                                checked={isChecked}
                                onChange={handleFormChange}
                                style={{minWidth: '32px'}}
                            />
                        </div>

                        {!isChecked ?
                            <div className="btn fl-btn-white w-100 mt-2" onClick={() => handleInviteUser('viewer')}>
                                {isLoading ?
                                    <div className="spinner-border" role="status" style={{width: '16px', height: '16px'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    :
                                    'Share memorial'
                                }
                            </div>
                            :
                            <div className="btn fl-btn-white w-100 mt-2" onClick={() => handleInviteUser('collaborator')}>
                                {isLoading ?
                                    <div className="spinner-border" role="status" style={{width: '16px', height: '16px'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    :
                                    'Invite collaborators'
                                }
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            
        </>

    );
}