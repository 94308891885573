import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import 'animate.css';
import { ForeverLovedDaysType, GetUserType, MemorialTab, MemorialType, PageResultType } from "../AppType";
import { ASSET_URL, debug, fetchRecords } from "../../utils/AppUtil";
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";

type NetworkProps = {
    userId: string
}

export const MyNetwork: React.FC<NetworkProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Default page states
    const [noSuggestions, setNoSuggestions] = useState<boolean>(false);
    const [noExisting, setNoExisting] = useState<boolean>(false);

    return (
        <>
            <div className="search-input input-group m-3">
                <input type="text" className="form-control" placeholder="Search for a user" />
                <span className="input-group-text">
                    <img src="/images/fl-icon-search.svg" width={16} height={16} />
                </span>
            </div>

            {/* SUGGESTED */}
            <div className="account-content-heading">
                <p>Suggested</p>
                {noSuggestions ?
                    <p>Help us recommend Forever Loved connections</p> :
                    <p>Users we think you should add</p>
                }
            </div>

            <div className="my-network-suggested">

                {noSuggestions ?
                    <div className="card card-find-connections">
                        <img src="/images/img-find-connections.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <div className="card-text d-flex flex-column gap-2 mb-4">
                                <p className="title">Be the friend who cares</p>
                                <p className="subtitle">Grow your Forever Loved network to share memories and be reminded on special days.</p>
                            </div>
                            <button className="fl-btn-grey-large">Find your contacts</button>
                        </div>
                    </div>
                    :
                    <>

                        <div className="card card-find-connections">
                            <img src="/images/marry-campbell-memorial-placeholder-img.png" className="card-img-top profile-img" alt="..." />
                            <div className="card-body">
                                <div className="card-text d-flex flex-column gap-2 mb-4">
                                    <p className="title">Jane Doe</p>
                                    <p className="subtitle">Added you</p>
                                </div>

                                <div className="network-card-btn-wrapper">
                                    <button className="fl-btn-grey-large no-fill">
                                        <img src="images/fl-login-x.svg" />
                                    </button>

                                    <button className="fl-btn-grey-large">
                                        <img src="images/fl-icon-contact.svg" />
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Final card */}
                        <div className="card card-find-connections">
                            <img src="/images/img-find-connections.png" className="card-img-top profile-img" alt="..." />
                            <div className="card-body">
                                <div className="card-text d-flex flex-column gap-2 mb-4">
                                    <p className="title">Be the friend who cares</p>
                                    <p className="subtitle">Grow your Forever Loved network.</p>
                                </div>
                                <button className="fl-btn-grey-large">Find your contacts</button>
                            </div>
                        </div>
                    </>
                }

            </div>

            {/* EXISTING */}
            <div className="account-content-heading">
                <p>Existing network</p>
            </div>

            <div className="my-network-suggested" >

                {noExisting ?
                    <div className="no-suggestions">
                        <p className="title">Your network is currently empty</p>
                    </div>
                    :
                    <>

                        <div className="card card-existing-connections">
                            <img src="/images/marry-campbell-memorial-placeholder-img.png" className="profile-img" alt="..." />
                            <div className="card-body">
                                <div className="card-text d-flex flex-column gap-2">
                                    <p className="title">Jane Doe</p>
                                    <p className="subtitle">10 mutual connections</p>
                                </div>

                                <div  style={{gap: '24', height: '24', width:'28', borderLeft: '1px solid #FFFFFF0D',  display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '12px', marginLeft:'16px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.646 1.64604C4.69245 1.59948 4.74762 1.56253 4.80837 1.53733C4.86911 1.51212 4.93423 1.49915 5 1.49915C5.06577 1.49915 5.13089 1.51212 5.19163 1.53733C5.25238 1.56253 5.30756 1.59948 5.354 1.64604L11.354 7.64604C11.4006 7.69248 11.4375 7.74766 11.4627 7.80841C11.4879 7.86915 11.5009 7.93427 11.5009 8.00004C11.5009 8.06581 11.4879 8.13093 11.4627 8.19167C11.4375 8.25242 11.4006 8.30759 11.354 8.35404L5.354 14.354C5.26011 14.4479 5.13278 14.5007 5 14.5007C4.86723 14.5007 4.73989 14.4479 4.646 14.354C4.55211 14.2602 4.49937 14.1328 4.49937 14C4.49937 13.8673 4.55211 13.7399 4.646 13.646L10.293 8.00004L4.646 2.35404C4.59944 2.30759 4.56249 2.25242 4.53729 2.19167C4.51208 2.13093 4.49911 2.06581 4.49911 2.00004C4.49911 1.93427 4.51208 1.86915 4.53729 1.80841C4.56249 1.74766 4.59944 1.69249 4.646 1.64604Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="card card-existing-connections">
                            <img src="/images/marry-campbell-memorial-placeholder-img.png" className="profile-img" alt="..." />
                            <div className="card-body">
                                <div className="card-text d-flex flex-column gap-2">
                                    <p className="title">Jane Doe</p>
                                    <p className="subtitle">10 mutual connections</p>
                                </div>

                                <div  style={{gap: '24', height: '24', width:'28', borderLeft: '1px solid #FFFFFF0D',  display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '12px', marginLeft:'16px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.646 1.64604C4.69245 1.59948 4.74762 1.56253 4.80837 1.53733C4.86911 1.51212 4.93423 1.49915 5 1.49915C5.06577 1.49915 5.13089 1.51212 5.19163 1.53733C5.25238 1.56253 5.30756 1.59948 5.354 1.64604L11.354 7.64604C11.4006 7.69248 11.4375 7.74766 11.4627 7.80841C11.4879 7.86915 11.5009 7.93427 11.5009 8.00004C11.5009 8.06581 11.4879 8.13093 11.4627 8.19167C11.4375 8.25242 11.4006 8.30759 11.354 8.35404L5.354 14.354C5.26011 14.4479 5.13278 14.5007 5 14.5007C4.86723 14.5007 4.73989 14.4479 4.646 14.354C4.55211 14.2602 4.49937 14.1328 4.49937 14C4.49937 13.8673 4.55211 13.7399 4.646 13.646L10.293 8.00004L4.646 2.35404C4.59944 2.30759 4.56249 2.25242 4.53729 2.19167C4.51208 2.13093 4.49911 2.06581 4.49911 2.00004C4.49911 1.93427 4.51208 1.86915 4.53729 1.80841C4.56249 1.74766 4.59944 1.69249 4.646 1.64604Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
}