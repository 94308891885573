import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { LoginType, UserType } from "../AppType";
import { postFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { useNavigate } from "react-router-dom";


type ModalProps = {
    loggedOutState: boolean;
    setInputFocus: React.Dispatch<React.SetStateAction<boolean>>;
    inviteId?: string
};

export const ModalCreateAccount: React.FC<ModalProps> = (props) => {
   
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [formStep, setFormStep] = useState<number>(0);
    
    const [modalAnimation, setModalAnimation] = useState<string>("modal-deactivate-scroll");
    const [formProgressHide, setFormProgressHide] = useState<string>("animate__animated animate__fadeInLeft animate__faster");
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);

    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [formSuccessAnimation, setFormSuccessAnimation] = useState<string>("slideInRight");

    const refFirstName = useRef<HTMLInputElement>(null!);

    const refBirthDateDay = useRef<HTMLInputElement>(null!);
    const refBirthDateMonth = useRef<HTMLInputElement>(null!);
    const refBirthDateYear = useRef<HTMLInputElement>(null!);


    const [invalidBirthDate, setInvalidBirthDate] = useState<boolean>(false);
    const [invalidBirthDay, setInvalidBirthDay] = useState<boolean>(false);
    const [invalidBirthMonth, setInvalidBirthMonth] = useState<boolean>(false);
    const [invalidBirthYear, setInvalidBirthYear] = useState<boolean>(false);
    const [isValidEmail ,setIsValidEmail] = useState<boolean>(true);
    const [show, setShow] = useState<boolean>(false);
    const invalidBorder: string = '3px solid #DC3545';


    const baseUrlLogin: any = process.env.REACT_APP_BASE_LOGIN;
    const loginEndpoint = `${baseUrlLogin}`;

    const formData: UserType = {
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        password: "",
        dateOfBirth: "",
        birthDateDay: "",
        birthDateMonth: "",
        birthDateYear: ""
    }

    const [formState, setFormState] = useState<UserType>(formData);
    const [formStateLogin, setFormStateLogin] = useState<LoginType>({
        email: 'aaa@acme.com',
        password: 'PWD',
        inviteId: ''
    });
    
    const navigate = useNavigate();

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [show]);
 
    

    // Disable DOM zooming to allow pinching on image
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});
    
    const handleFormChange = (e: React.ChangeEvent<any>) => {
        if (e.target.type === "text") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.type === "email") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));

            const emailIsValid = validateEmail(e.target.value);
            setIsValidEmail(emailIsValid);

            setFormStateLogin((formStateLogin) => ({
                ...formStateLogin, 
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.type === "password") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));

            setFormStateLogin((formStateLogin) => ({
                ...formStateLogin, 
                [e.target.id]: e.target.value
            }));
        }
    }

    // Validation (day)
    const validateDay = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthDay(true);
            }

            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthDay(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthDay(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthDay(true);
            }

            return 'invalid';
        }
    }

    // Validation (month)
    const validateMonth = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthMonth(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthMonth(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthMonth(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthMonth(true);
            }

            return 'invalid';
        }
    }

    // Validation (year)
    const validateYear = (value: string): string => {

        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthYear(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return '000' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            } 

            return '00' + numberValue.toString();

        } else if (numberValue >= 100 && numberValue < 1000) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 1000 && numberValue < 10000) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthYear(true);
            }

            return 'invalid';
        }
    }

    // Form change (date fields)
    const handleFormChangeDate = (e: React.ChangeEvent<any>) => {
        //console.log(e.target.type);
        if (e.target.type === "tel") { //changed from 'text' to 'tel'

            // - - - - - Birth Date - - - - -
            if (e.target.id === "birthDateDay") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateDay(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refBirthDateMonth.current.focus();
                }
            }

            if (e.target.id === "birthDateMonth") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateMonth(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refBirthDateYear.current.focus();
                }
            }

            if (e.target.id === "birthDateYear") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateYear(e.target.value)
                }));
            }
        }
    }

    const passwordCreateGroup = document.getElementsByClassName('form-password-create');
    const passwordCreate = passwordCreateGroup[0] as HTMLInputElement;

    const handlePasswordCreateShow = () => {
        if (passwordCreate.type === "password") {
            passwordCreate.type = "text";
        } else {
            passwordCreate.type = "password";
        }
    }

    let conditionalStyle: React.CSSProperties = {}
    if (formStep === 2) {
        conditionalStyle = {
            backgroundColor: "#00222f",
            height: "calc(100dvh - (256px))"
        }
    }

    // - - - - - Change Form Step - - - - -
    const handleClickNext = () => {

        if (formStep === -1) {
            setFormProgressHide("animate__animated animate__fadeInRight animate__faster");

            setTimeout(() => {
                refFirstName.current.focus();
            }, 500);

            setFormStep(c => c + 1);

        } else if (formStep === 1) {
            if (formState.birthDateDay === "") {
                setInvalidBirthDay(true);
                setInvalidBirthDate(true);

            } else if (formState.birthDateMonth === "") {
                setInvalidBirthMonth(true);
                setInvalidBirthDate(true);
                
            } else if (formState.birthDateYear === "") {
                setInvalidBirthYear(true);
                setInvalidBirthDate(true);

            } else if (formState.birthDateDay != 'invalid' && formState.birthDateMonth != 'invalid' && formState.birthDateYear != 'invalid') {
                setFormState((formState) => ({
                    ...formState, 
                    dateOfBirth: formState.birthDateYear + "-" + formState.birthDateMonth + "-" + formState.birthDateDay
                }));
            
                setFormStep(c => c + 1);
            } else {
                setInvalidBirthDate(true);
            }

        } else if (formStep === 2) {
            handlePost();
        } else {
            setFormStep(c => c + 1);
        }
        


        // if (formStep === 1) {
        //     setFormStep(2);
        // }
        
        // if (formStep === 2) {
        //     setFormStep(3);

        //     if (!hasConnections) {
        //         console.log('connections')
        //         setTimeout(() => {
        //             setFormStep(4);
        //         }, 3000);
        //     }
        // }
        
    }

    const handleClickBack = () => {
        setFormStep(formStep - 1);

        if (formStep === 0) {
            setFormProgressHide("animate__animated animate__fadeOutLeft animate__faster");
        }

        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }

    const handleClickLogin = () => {
        handleClose();
        setFormStep(0);
    }

    const handleClose = () => {
        setShow(false);
        setTimeout(() => {
            setFormStep(0);
        }, 1000);
    }

    const handlePost = async () => {
        // e.preventDefault();
        await postFormData(
            commonData.token,
            'user',
            formState,
            (response: any) => { // success
            },
            (error: any) => { // error
            }
        );

        setFormSuccess(true);

        await postLoginData(loginEndpoint, formStateLogin,
            (response:any) => {
                console.log("TOKEN", response);
            },
            (response:any) => {
                console.log("FAILED",response);
        });

        setTimeout(() => {
            navigate('/');
            setFormState(formData);
            setFormStep(0);
            handleClose();
            setFormSuccess(false);
        }, 2000); 
    }

    const postLoginData = async (endpoint: string, data: any, success: any, failure: any) => {

        console.log("LOGIN DATA", JSON.stringify(data));
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setCommonData(await response.json());
            } else {
                failure("Login Failed", response);
            }
        } catch (error) {
            failure(error);
        }
    }

   
    const handleInputFocus = (active: boolean) => {
        props.setInputFocus(active);
    }

    // Define the email validation function if not already defined
    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }; 


    const handleShow = () => {
       if(validateEmail(formState.email)){
            setIsValidEmail(true);
            setShow(true); 
       }else{
          setIsValidEmail(false);
       }  
    }


    return (
        <>
           
           <div className="login-form" style={{height: 'auto'}}>
                <label htmlFor="emailNew" className="form-label">{ t("Login.Form.Email.label", {ns: "components"}) }</label>
                <input id="email" name="email" onChange={handleFormChange} onFocus={() => handleInputFocus(true)} onBlur={() => handleInputFocus(false)}  style={{border: isValidEmail ? "" :  invalidBorder  }} type="email" className="form-control" placeholder="name@domain.com" />
            </div> 

            { props.loggedOutState ? 
                <button className="btn fl-btn-large mt-3" onClick={handleShow} >Create account</button> :
                <span onClick={handleShow} style={{whiteSpace: "nowrap", textDecoration: "underline", cursor: "pointer"}}>{ t("Login.Account.createAccount", {ns: "components"}) }</span>
             }

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>

                <Modal.Header className={`${formStep === 4 ? 'py-3' : ''}`} style={{justifyContent: `${formStep === -1 ? 'start' : 'center'}`, background: `${formStep === -1 ? '#001b26' : ''}`}}>
                    {formStep !== 0 &&
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                    }

                    <p className="modal-title logo">forever loved</p>

                    <div className="btn btn-modal-close" onClick={handleClose}>
                        <img src="/images/modal-close-x.svg"/>
                    </div> 
                </Modal.Header>

                <Modal.Body>

                    {formStep >= 0 &&
                        <div className={`form-progress form-progress-absolute ${formProgressHide}`}>
                            <img src={`/images/fl-progress-heart.svg`} className={`form-progress-current form-progress-${formStep}`} />
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        </div>
                    }

                    <div className="form-container">

                        {/* 0: Name */}
                        <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>What's your name?</p>
                                </div>
                                
                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <input ref={refFirstName} id="firstName" name="firstName" type="text"onChange={handleFormChange} className="form-control" placeholder="Enter your own first name" required />
                                    </div>

                                    <div>
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input id="lastName" name="lastName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter your own last name" required />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 1: DoB */}
                        <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>When were you born?</p>
                                </div>
                                
                                <div className="login-form">
                                    <div>
                                        <label htmlFor="birthDateDay" className="form-label">Date of Birth</label>
                                        <div className="d-flex gap-3">
                                            <input ref={refBirthDateDay} id="birthDateDay" name="birthDateDay" type="tel" maxLength={2} onChange={handleFormChangeDate} className="form-control" placeholder="DD" style={{border: invalidBirthDay ? invalidBorder : ""}} />
                                            <input ref={refBirthDateMonth} id="birthDateMonth" name="birthDateMonth" type="tel" maxLength={2} onChange={handleFormChangeDate} className="form-control" placeholder="MM" style={{border: invalidBirthMonth ? invalidBorder : ""}} />
                                            <input ref={refBirthDateYear} id="birthDateYear" name="birthDateYear" type="tel" maxLength={4} onChange={handleFormChangeDate} className="form-control" placeholder="YYYY" style={{border: invalidBirthYear ? invalidBorder : ""}} />
                                        </div>
                                    </div>
                                </div>

                                {invalidBirthDate &&
                                    <div className="invalid-form-entry animate__animated animate__zoomIn">Please enter a valid date</div>
                                }
                            </div>
                        </div>

                        {/* 2: Password */}
                        <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep > 2 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Create a password</p>
                                </div>
                                
                                <div className="login-form">
                                    <div className="mb-2" style={{position: "relative"}}>
                                            <label htmlFor="password" className="form-label">{ t("Login.Form.Password.label", {ns: "components"}) }</label>
                                            <input id="password" name="password" type="password" onChange={handleFormChange} className="form-control form-password-create" placeholder="Enter a strong password" required />
                                            <img src="/images/fl-eye.svg" onClick={handlePasswordCreateShow} style={{position: "absolute", top: "63%", right: "16px", cursor: "pointer"}}/>
                                        </div>
                                        <p className="input-subtext">Must contain: 1 Upper Case Letter, 1 Number</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="d-flex flex-column align-items-center w-100">
                        {formStep === -1 ?
                            <p className="fl-txt-login-subheading mt-3 float-center">I already have an account. <span onClick={handleClickLogin} style={{textDecoration: "underline", cursor: "pointer"}}>Login</span></p>
                            :
                            <>
                            {formStep === 2 ?
                                <button className="btn fl-btn-large w-100" onClick={handleClickNext}>Create account</button>
                                :
                                <button className="btn fl-btn-large w-100" onClick={handleClickNext}>Next</button>
                            }
                            </>  
                        }
                    </div>
                </Modal.Footer>

                {formSuccess &&
                    <>
                        <div className={`modal-success-div d-flex align-items-center justify-content-center animate__animated animate__${formSuccessAnimation}`}>
                            <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
                                <img src="/images/fl-login-tick.svg" />
                                <div className="login-success-txt d-flex flex-column gap-1">
                                    <p>Account Created</p>
                                    <p>Welcome to Forever Loved</p>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal>
        </>
    );
}