import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { familyTreeUpdateType, GetUserType, MemorialType } from "../AppType";
import { ASSET_URL, postFormData } from "../../utils/AppUtil";
import { NetworkContext } from "../../NetworkContext";
import { UserContext } from "../../UserContext";

type ModalProps = {
    detailedNodeData: familyTreeUpdateType;

    id: string;
    memorial?: MemorialType;
    user?: GetUserType
    firstName: string;
    lastName: string;
    relation: string;
    posX: number;
    posY: number,
    displayType: string;
    handleOpen: boolean;
    pictureId: string;
    closeModal: () => void;
    openMemorial: () => void;
    handleExtentionClick: (
        firstName: string,
        posX: number,
        posY: number,
        treeNodeId: string
    ) => void;
    deleteNode: (id: string) => void;
}

interface HandleProps extends ModalProps {
    openModal: (modal: string) => void;
}

export const DetailedNode: React.FC<HandleProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // User data from UserContext
    const { userData, setUserData } = useContext(UserContext)!;

    // User data from NetworkContext
    const { networkData, setNetworkData } = useContext(NetworkContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal states
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [modalSuccessDelete, setModalSuccessDelete] = useState<boolean>(false);
    const [modalSuccessAnimation, setModalSuccessAnimation] = useState<string>('animate__animated animate__fadeIn animate__faster');

    const [memorialWithImage, setMemorialWithImage] = useState<boolean>(false);

    useEffect(() => {
        setShow(props.handleOpen);
        setIsLoading(false);

        if (props.displayType === 'memorial' && props.pictureId) {
            setMemorialWithImage(true);
        } else {
            setMemorialWithImage(false);
        }
    }, [props.handleOpen]);

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        props.closeModal();
    }

    const handleAddRelative = () => {
        props.handleExtentionClick(props.firstName, props.posX, props.posY, props.id);
        handleClose();
    }

    const handleDelete = () => {
        props.deleteNode(props.id);
        setModalSuccessDelete(true);
        
        setTimeout(() => {
            handleClose()
        }, 2000);

        setTimeout(() => {
            setModalSuccessDelete(false);
            setModalSuccessAnimation('animate__animated animate__fadeIn animate__faster');          
        }, 3000);
    }

    const handleClick = () => {
        setMemorialData(props.memorial as MemorialType);
        props.openMemorial();
        handleClose();
    }

    const handleModalChange = (modal: string) => {
        props.openModal(modal);
        setTimeout(() => {
            handleClose();
        }, 500);
    }

    let pictureSrc: string = '/images/headshot-placeholder.svg';

    if (props.pictureId) pictureSrc = `${ASSET_URL}/public/picture/${props.pictureId}`;
    else if (props.relation === 'Father' || props.relation === 'Brother' || props.relation === 'Son') pictureSrc = '/images/headshot-placeholder.svg';
    else if (props.relation === 'Mother' || props.relation === 'Sister' || props.relation === 'Daughter') pictureSrc = '/images/headshot-placeholder-female.svg';

    let tileBackground: any;
    if (memorialWithImage) tileBackground = {backgroundImage: `url('${pictureSrc}')`}

    const handleInviteUser = (type: string) => {
        setIsLoading(true);
        postFormData(
            commonData.token, 
            'invite/treeNode',
            props.id,
            (response: string) => {
                console.log('response - inviteId: ', response);
                handleOpenInviteTray(response, type);
            },
            (error: any) => { // error
                console.log('error: ', error);
            }
        );
    }

    const handleOpenInviteTray = (inviteId: string, type: string) => {
        if (navigator.share) {
            navigator.share({
                title: 'Foreverloved',
                text: `${userData.firstName} ${userData.lastName} has invited you to join their Family Tree`,
                url: `https://mvp8.foreverloved.com/login?id=${inviteId}&userFirstName=${userData.firstName}&userLastName=${userData.lastName}&userImage=${userData.profilePicture.pictureId}&memorialFirstName=${props.firstName}&memorialLastName=${props.lastName}&type=${type}`,
            })
            .then(() => console.log('Content shared successfully'))
            .catch((error) => console.error('Error sharing content:', error));
        } else {
            console.log('Web Share API is not supported on this device.');
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    const handleViewUser = () => {
        if (props.user) setNetworkData(props.user);
    }

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Body className="p-0">
                    <div className={`family-tree-tile-view `}>
                        <div className={`btn btn-modal-close`} onClick={handleClose} style={{zIndex: 9999}}>
                            <img src="/images/modal-close-x.svg"/>
                        </div>

                        <div className="selected-tile-wrapper">
                            <div className="background-text">{props.lastName}</div>

                            <div className={`family-tree-node ${props.displayType}-node selected-tile`} style={tileBackground}>

                                {memorialWithImage && <div className="memorial-tile-gradient" />}
                                <div className="d-flex flex-column align-items-center gap-2">
                                    {!memorialWithImage && <img src={pictureSrc} />}

                                    <div className="d-flex flex-column">
                                        <p className="node-name tile-name">{props.firstName}</p>
                                        <p className="node-name tile-name">{props.lastName}</p>
                                    </div>
                                </div>

                                <p className="node-relation tile-relation">{props.relation}</p>
                            </div>
                        </div>

                        {props.detailedNodeData.endpoint === 'loggedIn' && props.displayType === 'proxy-user' &&
                            <>
                                <div className="selected-tile-link" onClick={() => handleInviteUser('tree')}>
                                    <p>Invite {props.firstName}</p>
                                    {isLoading ?
                                        <div className="spinner-border" role="status" style={{width: '16px', height: '16px'}}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        :
                                        <img src="/images/fl-icon-send.svg" />
                                    }
                                </div>
                            </>
                        }

                        {props.detailedNodeData.endpoint === 'loggedIn' && props.displayType === 'proxy-memorial' &&
                            <>
                                <div className="selected-tile-link" onClick={() => handleModalChange('createMemorial')}>
                                    <p>Create {props.firstName}'s memorial</p>
                                    <img src="/images/fl-icon-create-memorial.svg" />
                                </div>

                                <div className="selected-tile-link" onClick={() => handleModalChange('linkMemorial')}>
                                    <p>Link an existing memorial</p>
                                    <img src="/images/fl-icon-connect-memorial.svg" />
                                </div>
                            </>
                        }

                        {props.displayType === 'user' &&
                            <>
                                <div className="selected-tile-link" onClick={handleViewUser}>
                                    <p>View {props.firstName}'s profile</p>
                                    <img src="/images/fl-icon-arrow-in-circle.svg" />
                                </div>
                            </>
                        }

                        {props.displayType === 'memorial' &&
                            <>
                                <div className="selected-tile-link" onClick={handleClick}>
                                    <p>View {props.firstName}'s memorial</p>
                                    <img src="/images/fl-icon-arrow-in-circle.svg" />
                                </div>
                            </>
                        }

                        {props.detailedNodeData.endpoint === 'loggedIn' &&
                            <>
                                <div className="selected-tile-link"
                                    onClick={handleAddRelative}
                                >
                                    <p>Add a relative to {props.firstName}</p>
                                    <img src="/images/fl-plus-lg.svg" width={24} height={24} />
                                </div>

                                <div className="selected-tile-bottom-pills">
                                    <div className="selected-tile-pill edit" onClick={() => handleModalChange('editRelationship')}>
                                        <img src="/images/pill-icon-pen.svg" />
                                        <p>Edit relationships</p>
                                    </div>

                                    <div className="selected-tile-pill remove" onClick={() => handleDelete()}>
                                        <img src="/images/pill-icon-trash.svg" />
                                        <p>Remove from tree</p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Modal.Body>

                {modalSuccessDelete && 
                    <div className={`modal-success-div ${modalSuccessAnimation}`} style={{zIndex: 999}}>
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{props.firstName} removed from Family Tree</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                }

            </Modal>
        </>
    );
}