import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { CurrencyType, GetUserType, GiftDonateType, GiftType, PictureUploadType, UserPictureUploadType } from "../AppType";
import { Modal } from "react-bootstrap";
import { ASSET_URL, debug, fetchRecords, postFormData, postFormPictureFormData } from "../../utils/AppUtil";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';
import AvatarEditor from "react-avatar-editor";
import { useSpring } from "@react-spring/web";
import imageCompression from "browser-image-compression";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";

const useGesture = createUseGesture([dragAction, pinchAction]);

export const ModalVerifyAccount: React.FC = () => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Refs
    const refs = useRef<(HTMLDivElement | null)[]>([]);

    // States
    const [formStep, setFormStep] = useState<number>(0);
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalFail, setModalFail] = useState<string>("");
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);

    const [btnNextAnimation, setBtnNextAnimation] = useState<string>("");
    const [btnSkipAnimation, setBtnSkipAnimation] = useState<string>("");

    // Cropping & zooming refs
    const pinchRef = useRef<HTMLDivElement>(null!)
    const editorRef = useRef<AvatarEditor>(null!);
    const pictureInputRef = useRef<HTMLInputElement>(null!);

    // formState for loading image to gallery
    const initFormUpload: UserPictureUploadType = {
        memorialId: memorialData.memorialId,
        file: null
    }
    const [formStateUpload, setFormStateUpload] = useState<UserPictureUploadType>(initFormUpload);

    const [newImage, setNewImage] = useState<File>();
    const [newImageArray, setNewImageArray] = useState<File[]>([]);
    const [imageSelected, setImageSelected] = useState<boolean>(false);
    const [zoom, setZoom] = useState<number>(1);

    const [pictureSrc, setPictureSrc] = useState<string>('/images/fl-btn-profile-add-borderless.svg');

    useEffect(() => {
        fetchRecords(commonData.token, `user/loggedIn`, processUserData)
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'user');
        });
    }, [show])

    const processUserData = (responseJson: GetUserType) => {
        if (responseJson.profilePicture.pictureId) {
            setPictureSrc('/images/fl-btn-pen-borderless.svg');
        }
    }

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (show: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        // Define a ref to store the starting X position of the touch
        const startX = useRef<number | null>(null);
    
        useEffect(() => {
            const handleTouchStart = (e: TouchEvent) => {
                if (modalRef.current) {
                    startX.current = e.touches[0].clientX;
                }
            };
    
            const handleTouchMove = (e: TouchEvent) => {
                if (modalRef.current && startX.current !== null) {
                    const modal = modalRef.current;
                    const touchX = e.touches[0].clientX;
                    const deltaX = startX.current - touchX;
    
                    const canScrollLeft = modal.scrollLeft > 0;
                    const canScrollRight = modal.scrollWidth > modal.clientWidth + modal.scrollLeft;
    
                    if (modal.contains(e.target as Node)) {
                        // Scrolling inside the modalRef div
                        if ((deltaX > 0 && !canScrollRight) || (deltaX < 0 && !canScrollLeft)) {
                            e.preventDefault(); // Prevent scrolling outside the modalRef div
                        }
                    } else {
                        // Scrolling outside the modalRef div
                        e.preventDefault(); // Prevent scrolling the entire modal
                    }
    
                    startX.current = touchX; // Update startX for continuous movement
                }
            };
    
            if (show) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchstart', handleTouchStart, { passive: true });
                window.addEventListener('touchmove', handleTouchMove, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
            }
    
            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
            };
        }, [show, modalRef]); // Dependencies for the useEffect hook
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);


    // Deactivate DOM zooming to allow uninterupted image zooming
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    // Touch-pinch-zoom actions
    useGesture(
        {
            onDrag: ({pinching, cancel, offset: [x, y], ...rest}) => {
                if (pinching) return cancel();
                api.start({ x, y });
            },
            onPinch: ({offset: [s], first}) => {
                if (first) {
                    const {width, height, x, y} = pinchRef.current!.getBoundingClientRect();
                    const tx = x + width / 2;
                    const ty = y + height / 2;
                    api.start({ x: -tx, y: -ty });
                }
                setZoom(s);
            },
        },
        {
            target: pinchRef,
            drag: {from: () => [style.x.get(), style.y.get()]},
            pinch: {scaleBounds: {min: 0.25, max: 5}, rubberband: false},
        }
    );

    // Initial settings for touch-zoom functionality
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: zoom,
        rotateZ: 0,
    }));

    // Mousewheel scroll Zoom
    const scaleStep = 0.1;
    document.getElementById('selectedImageContainer')?.addEventListener('wheel', (event) => {
        event.preventDefault();
        
        if (event.deltaY < 0) {
            setZoom(zoom + scaleStep);
        } else {
            setZoom(zoom - scaleStep);
        };
    });


    // Form change
    const handleFormChange = (e: React.ChangeEvent<any>) => {
        
        if (e.target.type === "file" && e.target.files != null) {
            setNewImage(e.target.files[0]);
            setFormStateUpload((formState) => ({
                ...formState, 
                [e.target.id]: e.target.files[0]
            }));

            setNewImageArray(newImageArray => [...newImageArray, e.target.files[0]] );
            setImageSelected(false);
        }
    }

    // Image cropping
    const handleCrop = async (): Promise<Blob> => {
        const canvasImage: HTMLCanvasElement = editorRef.current.getImage();
    
        return new Promise((resolve, reject) => {
            canvasImage.toBlob((blob: any) => {
                if (!blob) {
                    reject(new Error("Failed to convert canvas to Blob"));
                    return;
                }
    
                let name = "";
                if (pictureInputRef?.current?.files?.length) {
                    name = pictureInputRef.current.files[0].name;
                }
    
                setFormStateUpload((formStateUpload): PictureUploadType => {
                    const form = { ...formStateUpload, file: blob };
                    return form;
                });
    
                resolve(blob);
            });
        });
    }

    // Image compression
    const handleCompression = async (file: any): Promise<Blob> => {
        console.log('originalFile instanceof Blob', file instanceof Blob); // true
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
    
        try {
            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
            return compressedFile;
        } catch (error: any) {
            console.log(error.message);
            throw error;
        }
    }

    const handleAddImage = () => {
        pictureInputRef.current.click();
    }

    // Handle click to crop, compress & push
    const handleClick = async (): Promise<void> => {
        try {
            setFormStep(3);

            const croppedBlob = await handleCrop();
            console.log('Cropping completed successfully.');
            console.log('CROPPED:', croppedBlob);
    
            const compressedBlob = await handleCompression(croppedBlob);
            console.log('Compression completed successfully.');
            console.log('COMPRESSED:', compressedBlob);
    
            // Directly call handlePost* and pass the compressed file
            await handlePostUpload(compressedBlob);

        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    // Post image data to gallery
    const handlePostUpload = async (compressedFile: Blob) => {

        // Ensure form state has the latest file
        console.log('Posting Form State:', formStateUpload);
        console.log('Posting File:', compressedFile);

        const formData = new FormData();
        formData.set("file", compressedFile);
    
        postFormPictureFormData(
            commonData.token,
            `picture`,
            formData,
            (responseData: any) => {
                console.log("Multi-post Success", responseData);

                // setFormStateUser((formStateUser) => ({
                //     ...formStateUser,
                //     profilePictureId: responseData.pictureId
                // }));

                handlePostUser(responseData.pictureId);

                setFormStateUpload(initFormUpload);
            },
            (response: any) => { // error
                console.log("Multi-post Fail Upload", response);
            }
        );
    }

    const handlePostUser = (pictureId: string) => {
        postFormData(
            commonData.token,
            `user/loggedIn`,
            {profilePictureId: pictureId},
            (response: any) => { // Success
                setTimeout(() => {
                    handleClose();
                }, 2000);
            },
            (error: any) => { // Error
                console.log("Error:", error);
            }
        )
    }

    const handleSkip = () => {
        setFormStep(3);

        setTimeout(() => {
            handleClose();
        }, 2000);
    }

    // On modal show
    const handleShow = () => {
        setShow(true);
        setFormStep(0);
    }

    // On modal hide
    const handleClose = () => {
        setShow(false);
    }

    // Progress to next step
    const handleClickNext = () => {
        setFormStep(formStep + 1);
    }

    // Progress to previous step
    const handleClickBack = () => {
        if (formStep === 2 && newImage) setNewImage(undefined);
        else {
            setFormStep(formStep - 1);
            
            setIsTransitioning(true);
            setTimeout(() => {
                setIsTransitioning(false);
            }, 500);
        }

    }

    // Set formState on input updates
    // const handleFormChange = (e: React.ChangeEvent<any>) => {
    //     setFormState(() => ({
    //         ...formState,
    //         [e.target.name]: e.target.value
    //     }));
    // }

    return (
        <>
            <input ref={pictureInputRef} id="file" name="file" type="file" accept="image/*" onChange={handleFormChange} style={{display: "none"}} />

            <div className="my-account-tile" onClick={handleShow}>
                <img src="images/img-help-and-support.svg" />
                <p>Help & Support</p>
            </div>

            {/* Modal content */}
            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>

                <Modal.Header>
                    {formStep === 0 ?
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClose}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                        :
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                    }
                    <p className="modal-title">foreverloved</p>
                    <div className="btn btn-modal-close" onClick={handleClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-progress form-progress-absolute">
                        <img src={`/images/fl-progress-verify.svg`} className={`form-progress-current form-progress-${formStep}`} alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                    </div>

                    <div className="form-container">

                        {/* STEP 0 - TELEPHONE NUMBER */}
                        <div className={`form-screen form-content px-3 form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2 mb-4">
                                <p className="modal-heading">{commonData.firstName}, please verify<br/>your account</p>
                                <p className="modal-subheading">We’ll send a confirmation code to your phone</p>
                            </div>

                            <div className="login-form">
                                <label htmlFor="firstname" className="form-label">Mobile number</label>
                                <div className="d-flex gap-2">
                                    <select id="gender" name="gender" className="form-select" style={{width: "auto", backgroundPositionX: "80%"}}>
                                        <option value="" className="select-option" selected disabled>Flag</option>
                                        <option className="select-option">Flag</option>
                                        <option className="select-option">Flag</option>
                                        <option className="select-option">Flag</option>
                                    </select>

                                    <input id="mobileNumber" name="mobileNumber" type="tel" className="form-control" placeholder="+44" required />
                                </div>
                            </div>
                        </div>

                        {/* STEP 1 - VERIFICATION CODE */}
                        <div className={`form-screen form-content px-3 form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2 mb-4">
                                <p className="modal-heading">Enter your code</p>
                                <p className="modal-subheading">Check your phone for a code from us</p>
                            </div>

                            <div className="login-form">
                                <div className="d-flex justify-content-center gap-3 mb-4">
                                    <input id="codeNum1" name="codeNum1" type="tel" className="form-control" required />
                                    <input id="codeNum2" name="codeNum2" type="tel" className="form-control" required />
                                    <input id="codeNum3" name="codeNum3" type="tel" className="form-control" required />
                                    <input id="codeNum4" name="codeNum4" type="tel" className="form-control" required />
                                </div>

                                <div className="d-flex justify-content-center">
                                    <p className="resend-code-txt" onClick={handleClickNext}>Didn't receive a code? <span>Resend it</span></p>
                                </div>
                            </div>

                            
                        </div>

                        {/* STEP 0 - PROFILE PICTURE */}
                        <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep > 2 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>                            
                            {newImage ?
                                <>
                                    <div ref={pinchRef} id="deactivateZoom" style={{height: "100vw"}}>
                                        <div className="d-flex justify-content-center">
                                            <div className="image-canvas-grid" />
                                            
                                            <div className="memorial-img-crop-wrapper d-flex justify-content-center align-items-center">
                                                <div id="selectedImageContainer" className="memorial-img-crop-preview">
                                                    {newImage &&
                                                        <AvatarEditor
                                                            ref={editorRef}
                                                            image={newImage}
                                                            border={0}
                                                            borderRadius={500}
                                                            scale={zoom}
                                                            rotate={0}
                                                            style={{
                                                                transform: `translate(${style.x}px, ${style.y}px) scale(${style.scale}) rotate(${style.rotateZ}deg)`,
                                                                touchAction: "pinch-zoom",
                                                                width: "100vw",
                                                                height: "100vw"
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div className="d-flex flex-column w-100">
                                        <div className="d-flex justify-content-center gap-2 p-3">
                                            <img src="/images/pinch-zoom.svg" />
                                            <p className="pinach-zoom-text">Pinch & zoom to crop</p>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="d-flex flex-column gap-2 px-3 mb-4">
                                        <p className="modal-heading">Add a profile picture</p>
                                        <p className="modal-subheading">Make your presence more personal</p>
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center h-100">
                                    
                                        <div className="add-photo-btn" onClick={handleAddImage}>
                                            <img src="/images/fl-plus-lg.svg" width={32} height={32} />
                                            <p>Click to upload a photo</p>
                                        </div>
    
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <div className={`form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep > 3 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                        <div className="d-flex flex-column h-100">
                            <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                                <img src="/images/fl-login-tick.svg" />
                                <div className="login-success-txt d-flex flex-column gap-1">
                                    <p><b>Account verified</b></p>
                                    <p>Let's get started</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {formStep === 0 &&
                        <button className="btn btn-modal-footer" onClick={handleClickNext}>Send code</button>
                    }

                    {formStep === 2 &&
                        (newImage ?
                        <button className="btn btn-modal-footer" onClick={handleClick}>Confirm</button>
                        :
                        <button className={`btn-modal-footer-skip ${btnSkipAnimation}`} onClick={handleSkip}>Skip</button>)
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}
