import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { debug } from './utils/AppUtil';
import { GetUserType } from './components/AppType';

// Define types for your context data
interface NetworkContextData {
  networkData: GetUserType;
  setNetworkData: React.Dispatch<React.SetStateAction<GetUserType>>;
}

// Create a context object
export const NetworkContext = createContext<NetworkContextData | undefined>(undefined);

// Create a provider component
export const NetworkProvider: React.FC<{children: ReactNode}> = (props) => {
  	const networkDefault: GetUserType = {
			userId: '',
			firstName: '',
			lastName: '',
			mobileNumber: '',
			gender: '',
			email: '',
			password: '',
			dateOfBirth: '',
			profilePicture: {
				pictureId: '',
				isVideo: false
			}
		}

  const [networkData, setNetworkData] = useState<GetUserType>(networkDefault); // initial data

  useEffect(() => {
    debug("Network Context", JSON.stringify(networkData));
  }, [networkData]);

  return (
	<NetworkContext.Provider value={{networkData, setNetworkData}}>
		{props.children}
	</NetworkContext.Provider>
  );
};

