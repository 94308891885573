import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"
import { postFormData } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";

type ModalProps = {
    relative?: string,
    showModal: boolean,
    id: string
}

export const LivingPlaceholderPopup: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Modal states
    const [show, setShow] = useState(false);
    const [popupAnimation, setPopupAnimation] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.showModal) {
            handleShow();
        }
        setIsLoading(false);
    }, [props.showModal])

    // On modal show
    const handleShow = () => {
        setShow(true);
        setPopupAnimation('animate__animated animate__slideInUp');
    }

    // On modal hide
    const handleClose = () => {
        setPopupAnimation('animate__animated animate__slideOutDown');
        setTimeout(() => {
            setShow(false);
        }, 1000);
    }

    const handleInviteUser = () => {
        setIsLoading(true);
        postFormData(
            commonData.token, 
            'invite/treeNode',
            props.id,
            (response: string) => {
                console.log('response - inviteId: ', response);
                handleOpenInviteTray(response);
                handleClose();
            },
            (error: any) => { // error
                console.log('error: ', error);
            }
        );
    }

    const handleOpenInviteTray = (inviteId: string) => {
        if (navigator.share) {
            navigator.share({
                title: 'Foreverloved',
                text: `${commonData.firstName} ${commonData.lastName} has invited you to join their Family Tree`,
                url: `https://mvp8.foreverloved.com/login?inviteId=${inviteId}`,
            })
            .then(() => console.log('Content shared successfully'))
            .catch((error) => console.error('Error sharing content:', error));
        } else {
            console.log('Web Share API is not supported on this device.');
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    return (
        <>
            <Modal className="modal-add-relative" show={show} animation={false} backdrop onHide={handleClose}>
                <Modal.Body>
                    <div className={`add-relative-popup ${popupAnimation}`}>
                        <div className="popup-close" onClick={handleClose}><div /></div>
                        <div className="d-flex flex-column gap-4">
                            <div className="add-deceased-relative-popup-txt d-flex flex-column gap-2">
                                <p>Invite {props.relative}</p>
                                <p>Allow {props.relative} to view your tree and the memorials on it with the invite link below.</p>
                            </div>

                            <div className="btn fl-btn-white" onClick={handleInviteUser}>
                                {isLoading ?
                                    <div className="spinner-border" role="status" style={{width: '16px', height: '16px'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    :
                                    'Copy invite link'
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}