import React, { useCallback, useContext, useEffect, useState } from "react";
import { Handle, Position, useEdges } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import "animate.css"
import { ASSET_URL } from "../../utils/AppUtil";
import { GetUserType, MemorialType } from "../AppType";
import { AppContext } from "../../AppContext";

type NodeProps = {
    data: {
        id: string,
        position: { x: number, y: number },
        fromTreeNodeId: [],
        handleFrom: string,

        user: GetUserType,
        memorial: MemorialType,

        displayType: string,
        firstName: string,
        lastName: string,
        relation: string,
        pictureId: string,
        gender: string,

        targetHandle: Position,
        sourceHandleTop: Position,
        sourceHandleRight: Position,
        sourceHandleBottom: Position,
        sourceHandleLeft: Position,

        targetHandleId: string,
        sourceHandleTopId: string,
        sourceHandleRightId: string,
        sourceHandleBottomId: string,
        sourceHandleLeftId: string,

        endpoint: string,

        handleExtentionClick: (
            firstName: string,
            posX: number,
            posY: number,
            treeNodeId: string
        ) => void,

        handleClickToTree: (endpoint: string) => void,

        handleNodeClick: (
            treeNodeId: string,
            user: GetUserType,
            memorial: MemorialType,
            proxyFirstName: string,
            proxyLastName: string,
            proxyType: string,
            relationship: string,
            posX: number,
            posY: number,
            handleTo: string,
            handleFrom: string,
            fromTreeNodeId: [],
            displayType: string,
            pictureId: string,
            endpoint: string
        ) => void
    }
}

export const TreeNode: React.FC<NodeProps> = ({ data }) => {

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    const [showExtension, setShowExtension] = useState<boolean>(false);
    const [memorialWithImage, setMemorialWithImage] = useState<boolean>(false);
    const edges = useEdges(); // Get all edges in the graph

    const checkHandleConnected = (handleId: string) => {
        return edges.some(edge => 
            edge.sourceHandle === handleId || edge.targetHandle === handleId
        );
    };

    const handleIds = [
        data.sourceHandleTopId,
        data.sourceHandleRightId,
        data.sourceHandleBottomId,
        data.sourceHandleLeftId
    ];

    useEffect(() => {

        // Check if any handle is connected
        const anyConnected = handleIds.some((handleId: string) => checkHandleConnected(handleId));
        
        // if (anyConnected) {
        //     setShowExtension(false);
        // }

        if (data.endpoint === 'loggedIn' || data.endpoint === commonData.userId) setShowExtension(true);
        else setShowExtension(false);

        if (data.displayType === 'memorial' && data.pictureId) {
            setMemorialWithImage(true);
        } else {
            setMemorialWithImage(false);
        }

    }, [handleIds, checkHandleConnected]);

    const handleStyle = (handleId: string) => {
        return {
            background: 'white',
            display: checkHandleConnected(handleId) ? 'block' : 'none'
        };
    };

    const onChange = useCallback((evt: any) => {
        console.log(evt.target.value);
    }, []);

    let pictureSrc: string = '/images/headshot-placeholder.svg';
    if (data.pictureId) pictureSrc = `${ASSET_URL}/public/picture/${data.pictureId}`;
    else if ((data.relation === 'Father' || data.relation === 'Brother' || data.relation === 'Son') || data.gender === 'MALE') pictureSrc = '/images/headshot-placeholder.svg';
    else if ((data.relation === 'Mother' || data.relation === 'Sister' || data.relation === 'Daughter') || data.gender === 'FEMALE') pictureSrc = '/images/headshot-placeholder-female.svg';

    let tileBackground: any;
    if (memorialWithImage) tileBackground = {backgroundImage: `url('${pictureSrc}')`}

    return (
        <>
            <Handle
                id={data.targetHandleId}
                type="target"
                position={data.targetHandle}
                style={{background: 'white'}} />

            <Handle
                id={data.sourceHandleTopId}
                type="source"
                position={data.sourceHandleTop}
                style={handleStyle(data.sourceHandleTopId)} />

            <Handle
                id={data.sourceHandleRightId}
                type="source"
                position={data.sourceHandleRight}
                style={handleStyle(data.sourceHandleRightId)} />

            <Handle
                id={data.sourceHandleBottomId}
                type="source"
                position={data.sourceHandleBottom}
                style={handleStyle(data.sourceHandleBottomId)} />

            <Handle
                id={data.sourceHandleLeftId}
                type="source"
                position={data.sourceHandleLeft}
                style={handleStyle(data.sourceHandleLeftId)} />

            {showExtension &&
                <>
                    <div className={`family-tree-extension ${data.displayType}`}
                        onClick={() => data.handleExtentionClick(
                            data.firstName,
                            data.position.x,
                            data.position.y,
                            data.id
                        )}
                    >
                        <img src="images/fl-plus.svg" />
                    </div>
                </>
            }

            {data.user && data.displayType !== 'owner' &&
                <div className={`family-tree-of-user ${showExtension && 'owner'}`}
                    onClick={() => data.handleClickToTree(data.user.userId)}
                >
                    <img src="images/tab-icon-family-tree.svg" width={16} height={16} />
                </div>
            }

            <div className={`family-tree-node ${data.displayType}-node`}
                onClick={() => {
                    // console.log("FROM TREE NODE ID: ", data.fromTreeNodeId);
                
                    data.handleNodeClick(
                    data.id,
                    data.user,
                    data.memorial,
                    data.firstName,
                    data.lastName,
                    data.displayType,
                    data.relation,
                    data.position.x,
                    data.position.y,
                    data.targetHandle,
                    data.handleFrom,
                    data.fromTreeNodeId,
                    data.displayType,
                    data.pictureId,
                    data.endpoint
                )}}
                style={tileBackground}
            >
                {memorialWithImage && <div className="memorial-tile-gradient" />}
                <div className="d-flex flex-column align-items-center gap-2">
                    {!memorialWithImage && <img src={pictureSrc} />}

                    <div className="node-name">
                        <p>{data.firstName}</p>
                        <p>{data.lastName}</p>
                    </div>
                </div>

                <p className="node-relation">{data.relation}</p>
            </div>
        </>
      );
}