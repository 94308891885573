import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { ASSET_URL, debug, fetchRecords, postFormData } from "../../utils/AppUtil";
import { MemorialTab, MemorialType, NotificationArrayType, NotificationGroupsType, NotificationType } from "../AppType";

type ModalProps = {
    openMemorial: (tab: MemorialTab) => void
}

export const UserNotifications: React.FC<ModalProps> = ({openMemorial}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const [activeType, setActiveType] = useState<keyof NotificationGroupsType | null>('ALL');
    const [groupedUnreadNotifications, setGroupedUnreadNotifications] = useState<NotificationGroupsType | null>(null);
    const [groupedReadNotifications, setGroupedReadNotifications] = useState<NotificationGroupsType | null>(null);

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (showModal: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => {
                if (modalRef.current && modalRef.current.contains(e.target as Node)) {
                    return; // Allow scrolling inside the modal
                }

                e.preventDefault(); // Prevent scrolling outside the modal (i.e., body scroll)
            };

            if (showModal) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchmove', preventScroll);
            }

            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [showModal, modalRef]);
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    // Fetch unread and read notifications
    useEffect(() => {
        fetchRecords(
            commonData.token,
            `user/loggedIn/notifications?isRead=false&page=0&size=20`,
            processUnreadNotificationsData
        )
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'notifications: UNREAD');
        });

        fetchRecords(
            commonData.token,
            `user/loggedIn/notifications?isRead=true&page=0&size=20`,
            processReadNotificationsData
        )
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'notifications: READ');
        });
    }, [commonData, show, memorialData]);



    // =====================
    // Unread notifications
    // =====================
    // #region

    const processUnreadNotificationsData = (responseJson: NotificationArrayType) => {

        const allGrouped: NotificationType[] = [];
        const memoriesGrouped: NotificationType[] = [];
        const lifeEventsGrouped: NotificationType[] = [];
        const leavesGrouped: NotificationType[] = [];
        const giftsGrouped: NotificationType[] = [];
        const familyTreeGrouped: NotificationType[] = [];
        const connectionsGrouped: NotificationType[] = [];

        responseJson.data.forEach((notification: NotificationType) => {
            allGrouped.push(notification);

            switch(notification.notificationType) {
                case 'NEW_MEMORY':
                    memoriesGrouped.push(notification);
                    break;

                case 'NEW_LIFE_EVENT':
                    lifeEventsGrouped.push(notification);
                    break;

                case 'NEW_LEAF':
                    leavesGrouped.push(notification);
                    break;

                case 'NEW_GIFT':
                    giftsGrouped.push(notification);
                    break;

                case 'ADD_ME_TO_TREE':
                    familyTreeGrouped.push(notification);
                    break;

                case 'ADD_ME_TO_CONNECTED':
                    connectionsGrouped.push(notification);
                    break;
            }
        });

        setGroupedUnreadNotifications({
            ALL: {
                name: 'All',
                data: allGrouped
            },
        
            NEW_MEMORY: {
                name: 'Memories',
                data: memoriesGrouped
            },
        
            NEW_LIFE_EVENT: {
                name: 'Life Events',
                data: lifeEventsGrouped
            },
        
            NEW_LEAF:{
                name: 'Leaves',
                data: leavesGrouped
            },
        
            NEW_GIFT: {
                name: 'Gifts',
                data: giftsGrouped
            },
        
            ADDED_ME_TO_TREE: {
                name: 'Family Tree',
                data: familyTreeGrouped
            },
        
            ADDED_ME_TO_CONNECTED: {
                name: 'Connections',
                data: connectionsGrouped
            }
        });
    }

    const buildUnreadNotificationsJSX = () => {
        if (!groupedUnreadNotifications || !activeType || !groupedUnreadNotifications[activeType]) {
            return null;
        }
    
        return groupedUnreadNotifications[activeType].data.map((notification: NotificationType) => {
            const user: string = `${notification.relatedUser.firstName} ${notification.relatedUser.lastName}`;
            const memorial: string = `${notification.relatedMemorial?.firstName} ${notification.relatedMemorial?.lastName}`;
    
            let profilePictureSrc = "images/fl-avatar-min.svg";
            if (notification.relatedUser.profilePicture) {
                profilePictureSrc = `${ASSET_URL}/public/picture/${notification.relatedUser.profilePicture.pictureId}`;
            } else if (notification.relatedUser.gender === 'MALE') {
                profilePictureSrc = '/images/headshot-placeholder.svg';
            } else if (notification.relatedUser.gender === 'FEMALE') {
                profilePictureSrc = '/images/headshot-placeholder-female.svg';
            }
    
            let notificationContent: any;
            let memorialTab: MemorialTab;
            switch (notification.notificationType) {
                case 'NEW_MEMORY':
                    notificationContent = <><b>{user}</b> added a memory to <b>{memorial}'s</b> memorial</>;
                    memorialTab = MemorialTab.tab1;
                    break;
    
                case 'NEW_LIFE_EVENT':
                    notificationContent = <><b>{user}</b> added a life event to <b>{memorial}'s</b> memorial</>;
                    memorialTab = MemorialTab.tab1;
                    break;
    
                case 'NEW_LEAF':
                    notificationContent = <><b>{user}</b> donated a leaf on <b>{memorial}'s</b> memorial</>;
                    memorialTab = MemorialTab.tab2;
                    break;
    
                case 'NEW_GIFT':
                    notificationContent = <><b>{user}</b> placed a gift on <b>{memorial}'s</b> memorial</>;
                    memorialTab = MemorialTab.tab3;
                    break;
    
                case 'ADDED_ME_TO_TREE':
                    notificationContent = <><b>{user}</b> just joined the family tree</>;
                    break;
    
                case 'ADDED_ME_TO_CONNECTED':
                    notificationContent = <><b>{user}</b> added you to their network</>;
                    break;
    
                default:
                    notificationContent = null;
            }
    
            return (
                <div
                    key={notification.notificationId}
                    id={notification.notificationId}
                    className="list-item new"
                    onClick={() => {handleOpenMemorial(notification.relatedMemorial, memorialTab); handlePost(notification.notificationId)}}
                >
                    <img className="list-item-img" src={profilePictureSrc} />
                    <div className="list-item-text">
                        <p>{notificationContent}</p>
                        <span className="create-time">1 hour ago</span>
                    </div>
                </div>
            );
        });
    }

    // #endregion

    
    
    // =====================
    // Read notifications
    // =====================
    // #region

    const processReadNotificationsData = (responseJson: NotificationArrayType) => {

        const allGrouped: NotificationType[] = [];
        const memoriesGrouped: NotificationType[] = [];
        const lifeEventsGrouped: NotificationType[] = [];
        const leavesGrouped: NotificationType[] = [];
        const giftsGrouped: NotificationType[] = [];
        const familyTreeGrouped: NotificationType[] = [];
        const connectionsGrouped: NotificationType[] = [];

        responseJson.data.forEach((notification: NotificationType) => {
            allGrouped.push(notification);

            switch(notification.notificationType) {
                case 'NEW_MEMORY':
                    memoriesGrouped.push(notification);
                    break;

                case 'NEW_LIFE_EVENT':
                    lifeEventsGrouped.push(notification);
                    break;

                case 'NEW_LEAF':
                    leavesGrouped.push(notification);
                    break;

                case 'NEW_GIFT':
                    giftsGrouped.push(notification);
                    break;

                case 'ADD_ME_TO_TREE':
                    familyTreeGrouped.push(notification);
                    break;

                case 'ADD_ME_TO_CONNECTED':
                    connectionsGrouped.push(notification);
                    break;
            }
        });

        setGroupedReadNotifications({
            ALL: {
                name: 'All',
                data: allGrouped
            },
        
            NEW_MEMORY: {
                name: 'Memories',
                data: memoriesGrouped
            },
        
            NEW_LIFE_EVENT: {
                name: 'Life Events',
                data: lifeEventsGrouped
            },
        
            NEW_LEAF:{
                name: 'Leaves',
                data: leavesGrouped
            },
        
            NEW_GIFT: {
                name: 'Gifts',
                data: giftsGrouped
            },
        
            ADDED_ME_TO_TREE: {
                name: 'Family Tree',
                data: familyTreeGrouped
            },
        
            ADDED_ME_TO_CONNECTED: {
                name: 'Connections',
                data: connectionsGrouped
            }
        });
    }

    const buildReadNotificationsJSX = () => {
        if (!groupedReadNotifications || !activeType || !groupedReadNotifications[activeType]) {
            return null;
        }
    
        return groupedReadNotifications[activeType].data.map((notification: NotificationType) => {
            const user: string = `${notification.relatedUser.firstName} ${notification.relatedUser.lastName}`;
            const memorial: string = `${notification.relatedMemorial.firstName} ${notification.relatedMemorial.lastName}`;
    
            let profilePictureSrc = "images/fl-avatar-min.svg";
            if (notification.relatedUser.profilePicture) {
                profilePictureSrc = `${ASSET_URL}/public/picture/${notification.relatedUser.profilePicture.pictureId}`;
            } else if (notification.relatedUser.gender === 'MALE') {
                profilePictureSrc = '/images/headshot-placeholder.svg';
            } else if (notification.relatedUser.gender === 'FEMALE') {
                profilePictureSrc = '/images/headshot-placeholder-female.svg';
            }
    
            let notificationContent;
            let memorialTab: MemorialTab;
            switch (notification.notificationType) {
                case 'NEW_MEMORY':
                    notificationContent = <><b>{user}</b> added a memory to <b>{memorial}'s</b> memorial</>;
                    memorialTab = MemorialTab.tab1;
                    break;
    
                case 'NEW_LIFE_EVENT':
                    notificationContent = <><b>{user}</b> added a life event to <b>{memorial}'s</b> memorial</>;
                    memorialTab = MemorialTab.tab1;
                    break;
    
                case 'NEW_LEAF':
                    notificationContent = <><b>{user}</b> donated a leaf on <b>{memorial}'s</b> memorial</>;
                    memorialTab = MemorialTab.tab2;
                    break;
    
                case 'NEW_GIFT':
                    notificationContent = <><b>{user}</b> placed a gift on <b>{memorial}'s</b> memorial</>;
                    memorialTab = MemorialTab.tab3;
                    break;
    
                case 'ADDED_ME_TO_TREE':
                    notificationContent = <><b>{user}</b> just joined the family tree</>;
                    break;
    
                case 'ADDED_ME_TO_CONNECTED':
                    notificationContent = <><b>{user}</b> added you to their network</>;
                    break;
    
                default:
                    notificationContent = null;
            }
    
            return (
                <div
                    key={notification.notificationId}
                    className="list-item"
                    onClick={() => handleOpenMemorial(notification.relatedMemorial, memorialTab)}
                >
                    <img className="list-item-img" src={profilePictureSrc} />
                    <div className="list-item-text">
                        <p>{notificationContent}</p>
                        <span className="create-time">1 hour ago</span>
                    </div>
                </div>
            );
        });
    }

    // #endregion



    const handleOpenMemorial = (memorial: MemorialType, tab: MemorialTab) => {
        setMemorialData(memorial);
        openMemorial(tab);
        handleClose();
    }

    const handleNotificationType = (type: keyof NotificationGroupsType | null) => {
        setActiveType(type);
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handlePost = (id: string) => {
        postFormData(
            commonData.token,
            `user/loggedIn/notifications/${id}?isRead=true`,
            (response: any) => {
                console.log("Success - Notifications POST: ", response);
            },
            (error: any) => { // error
                console.log("Error - Notifications POST: ", error);
            }
        );
    }

    return (
        <>
            <img src="/images/fl-icon-heart-1.svg" className="home-icon-top" height={40} onClick={handleShow} />

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header className="align-start">
                    <p className="modal-title">Notifications</p>
                    <div className="btn btn-modal-close" onClick={handleClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                </Modal.Header>

                <Modal.Body ref={modalRef} className="p-0">
                    <div className="notification-groups-wrapper">
                        {groupedUnreadNotifications && Object.entries(groupedUnreadNotifications).map(([key, group]) => (
                            <div key={key}
                                className={`notification-pill ${activeType === key ? 'active' : ''}`}
                                onClick={() => handleNotificationType(key as keyof NotificationGroupsType)}
                            >
                                <b>{group.name}</b> {group.data.length !== 0 && `(${group.data.length})`}
                            </div>
                        ))}
                    </div>

                    <div className="form-screen scroll-active" style={{paddingTop: '50px'}}>
                        <div className="notification-list-wrapper">
                            {buildUnreadNotificationsJSX()}
                            {buildReadNotificationsJSX()}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}